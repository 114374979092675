import {Component, OnInit} from '@angular/core';
import {AppComponent} from 'src/app/app.component';
import { AppGlobals } from 'src/app/app.globals';
import {AppMainComponent} from 'src/app/app.main.component';

@Component({
    selector: 'app-menu',
    templateUrl: './app.menu.component.html'
})
export class AppMenuComponent implements OnInit {

    public modelSystem: any[];
    public isSidebarActive = true;

    constructor(public app: AppComponent, public appMain: AppMainComponent) {}

    ngOnInit() {

        this.isSidebarActive = true;

        this.modelSystem = [
            {
                label: 'Dashboard', icon: 'pi pi-home', routerLink: ['/home']
            },
            {
                label: 'Instances', 
                icon: 'pi pi-cog', 
                routerLink: ['/instances'],
                permission: 'CAN_VIEW_INSTANCES_LIST'
            },
            {
                label: 'Documents', icon: 'pi pi-id-card',
                permission: 'CAN_GET_DOCUMENT_LIST',
                routerLink: ['/documents'],
                items: [
                    {
                        label: 'Documents List',
                        icon: 'pi pi-bars',
                        routerLink: ['/documents'],
                        routerLinkActiveOptions: { exact: true },
                        permission: 'CAN_GET_DOCUMENT_LIST'
                    },
                    {
                        label: 'Categories List',
                        icon: 'pi pi-folder-open',
                        routerLink: ['/documents/categories'],
                        routerLinkActiveOptions: { exact: true },
                        permission: 'CAN_GET_DOCUMENT_CATEGORIES_LIST'
                    }
                ]
            },
            {
                label: 'Projects', icon: 'pi pi-map', routerLink: ['/projects'],
                permission: 'CAN_VIEW_PROJECTS_LIST'
            },
            {
                label: 'Suppliers', icon: 'pi pi-user', routerLink: ['/suppliers'],
                permission: 'CAN_VIEW_SUPPLIERS_DATA_LIST'
            },
            {
                label: 'Customers', icon: 'pi pi-briefcase', routerLink: ['/customers'],
                permission: 'CAN_GET_CUSTOMERS_LIST'
            },
            {
                label: 'Manage Users', icon: 'pi pi-id-card',
                permission: 'CAN_VIEW_USERS_LIST',
                routerLink: ['/users'],
                items: [
                    {
                        label: 'Groups List',
                        icon: 'pi pi-bars',
                        routerLink: ['/users/groups'],
                        routerLinkActiveOptions: { exact: true },
                        permission: 'CAN_VIEW_USERS_GROUPS_LIST',
                    },
                    {
                        label: 'Users List',
                        icon: 'pi pi-users',
                        routerLink: ['/users'],
                        routerLinkActiveOptions: { exact: true },
                        permission: 'CAN_VIEW_USERS_LIST',
                    }
                ]
            }
        ];
    }

    public userHasPermission(permission: string){
        return (permission != '' && permission != null ? (!AppGlobals.userHasPermission(permission) ? false : true) : true);
    }
    

    // activeMenu(event) {
    //     //console.log(event.target.classList);
    //     let node;
    //     if (event.target.classList.contains("p-submenu-header") == true) {
    //       node = "submenu";
    //     } else if (event.target.tagName === "SPAN") {
    //       node = event.target.parentNode.parentNode;
    //     } else {
    //       node = event.target.parentNode;
    //     }
    //     //console.log(node);
    //     if (node != "submenu") {
    //       let menuitem = document.getElementsByClassName("p-menuitem");
    //       for (let i = 0; i < menuitem.length; i++) {
    //         menuitem[i].classList.remove("active");
    //       }
    //       node.classList.add("active");
    //     }
    //   }
}
