<p-confirmDialog header="Attenzione" [style]="{width:'450px'}"></p-confirmDialog>
<div class="p-col-12">
	<div class="card">
		<p-toolbar styleClass="p-mb-4">
			<div class="p-toolbar-group-left">
				<div class="p-d-flex p-flex-column p-flex-md-row">
					<div style="margin-right: 2rem;display:none;">
						<label for="filter_serviceIstances">Service istances</label>
						<br />
						<p-dropdown [options]="serviceInstancesFilter" #dropdown_serviceIstances
							placeholder="Select service istance" (onChange)="getList(null)" [showClear]="true"
							[style]="{'min-width':'100%'}" optionLabel="name" name="serviceIstance" optionValue="id">
						</p-dropdown>
					</div>
				</div>
			</div>
			<div class="p-toolbar-group-right">
				<button pButton label="Create Job Order" *ngIf="userHasPermission('CAN_ADD_PROJECT')" (click)="createJobOrder()" class="p-button-oris"></button>
			</div>
		</p-toolbar>
		<p-table #dtJobOrder [value]="jobOrderList" dataKey="id" styleClass="p-datatable-oris" [rowHover]="true"
			[globalFilterFields]="[]" (onLazyLoad)="getList($event)" [lazy]="true" [totalRecords]="(totalRecords)"
			[rows]="(itemsPerPage)" [paginator]="true" [filterDelay]="0" [loading]="jobOrderListLoading">
			<ng-template pTemplate="caption">
				<div class="p-d-flex p-flex-column p-flex-md-row p-jc-md-between table-header">
					Job Orders List
					<span class="p-input-icon-left">
						<i class="pi pi-search"></i>
						<input pInputText type="text" (input)="dtCustomer.filterGlobal($event.target.value, 'contains')"
							placeholder="Global Search" />
					</span>
				</div>
			</ng-template>
			<ng-template pTemplate="header">
				<tr>
					<th pSortableColumn="name">
						<div class="p-d-flex p-jc-between p-ai-center">
							Name
							<p-sortIcon field="name"></p-sortIcon>
						</div>
					</th>
					<th pSortableColumn="description">
						<div class="p-d-flex p-jc-between p-ai-center">
							Description
							<p-sortIcon field="description"></p-sortIcon>
						</div>
					</th>
					<th pSortableColumn="startTime">
						<div class="p-d-flex p-jc-between p-ai-center">
							Start time
							<p-sortIcon field="startTime"></p-sortIcon>
						</div>
					</th>
					<th pSortableColumn="endTime">
						<div class="p-d-flex p-jc-between p-ai-center">
							End time
							<p-sortIcon field="endTime"></p-sortIcon>
						</div>
					</th>
					<th style="width: 8rem"></th>
				</tr>
			</ng-template>
			<ng-template pTemplate="body" let-order let-expanded="expanded">
				<tr class="p-selectable-row">
					<td>
						{{order.name}}
					</td>
					<td>
						{{order.description}}
					</td>
					<td>
						{{order.startTime | date: 'dd/MM/yyyy hh:mm:ss'}}
					</td>
					<td>
						{{order.endTime | date: 'dd/MM/yyyy hh:mm:ss'}}
					</td>
					<td>
						<button pButton type="button" *ngIf="userHasPermission('CAN_EDIT_PROJECT')" title="Edit"
							icon="pi pi-pencil" class="p-button-rounded p-button-text"
							(click)="editJobOrder(jobOrder.id)"></button>
						<button pButton type="button" *ngIf="userHasPermission('CAN_DELETE_PROJECT')" title="Delete"
							class="p-button-text" icon="pi pi-trash"
							(click)="deleteJobOrder(jobOrder.id, jobOrder.name)"></button>
					</td>
				</tr>
			</ng-template>
			<ng-template pTemplate="emptymessage">
				<tr>
					<td>No records found</td>
				</tr>
			</ng-template>
		</p-table>
	</div>
</div>
<form #createJobOrderForm="ngForm" (ngSubmit)="createJobOrderForm.form.valid && saveJobOrder()">
	<p-dialog header="New Job Order" [(visible)]="jobOrderCreateModal" [modal]="true" [style]="{width: '1400px'}"
		styleClass="p-fluid" [baseZIndex]="10000" [draggable]="false" [resizable]="false">
		<hr />
		<div class="p-formgroup-inline">
			<div class="p-col-12">
				<div class="">
					<h4>Job Order Details</h4>
				</div>
			</div>
			<div class="p-col-3">
				<label for="jobOrderNew_name">Job Order Name</label>
				<input type="text" pInputText id="jobOrderNew_name" name="jobOrderNew_name" [(ngModel)]="jobOrder_new.name"
					#name='ngModel' required />
				<div *ngIf="createJobOrderForm.submitted && name.invalid" class="error-login-info">
					<small *ngIf="name.errors?.required" class="p-error">Enter job order name</small>
				</div>
			</div>
			<div class="p-col-3">
				<label for="jobOrderNewStartDate">Start Date</label>
				<p-calendar name="jobOrderNewStartDate"
					[style]="{'overflow-y':'visible', 'position':'fixed', 'z-index':999, 'width':'inherit'}"
					class='calender-overflow' dataType="date" dateFormat="dd/mm/yy" [(ngModel)]="jobOrder_new.startDate">
				</p-calendar>
			</div>
			<div class="p-col-3">
				<label for="jobOrderNewEndDate">End Date</label>
				<p-calendar name="jobOrderNewEndDate"
					[style]="{'overflow-y':'visible', 'position':'fixed', 'z-index':999, 'width':'inherit'}"
					class='calender-overflow' dataType="date" dateFormat="dd/mm/yy" [(ngModel)]="jobOrder_new.endDate">
				</p-calendar>
			</div>
			<div class="p-col-3">
				<label for="jobOrderNew_serviceInstance">Service Instance</label>
				<p-dropdown [options]="serviceInstanceList" [(ngModel)]="jobOrder_new.serviceInstanceId"
					[panelStyle]="{'overflow-y':'auto'}" placeholder="Select service instance" optionLabel="name"
					name="serverInstance" [baseZIndex]="10001" required optionValue="id" #service_instance='ngModel'>
				</p-dropdown>
				<div *ngIf="createJobOrderForm.submitted && service_instance.invalid" class="error-login-info">
					<small *ngIf="service_instance.errors?.required" class="p-error">Select service instance</small>
				</div>
			</div>
		</div>
		<div class="p-formgroup-inline">
			<div class="p-col-12">
				<div class="">
					<h4>Order Items</h4>
				</div>
				<div class="jobs-list">
					<table>
						<tr>
							<th>Quantity</th>
							<th>Unit</th>
							<th>Unity cost</th>
							<th style="width:150px">State</th>
							<th>Note</th>
							<th style="width:200px">Start time</th>
							<th style="width:200px">End time</th>
							<th>Actions</th>
						</tr>
						<ng-container *ngFor="let item of orderItemsData; let i = index;">
							<tr>
								<td><input type="number" pInputText value="{{item.quantity}}" /></td>
								<td><input type="number" pInputText value="{{item.unit}}" /></td>
								<td><input type="number" pInputText value="{{item.unitCost}}" /></td>
								<td><select pInputText>
										<option>{{item.state}}</option>
									</select></td>
								<td><input type="text" pInputText value="{{item.note}}" /></td>
								<td>
									<p-calendar name="jobItemStart"
										[style]="{'overflow-y':'visible', 'position':'fixed', 'z-index':999, 'width':'inherit'}"
										class='calender-overflow' dataType="date" dateFormat="dd/mm/yy"
										>
									</p-calendar>
								</td>
								<td>
									<p-calendar name="jobOrder"
										[style]="{'overflow-y':'visible', 'position':'fixed', 'z-index':999, 'width':'inherit'}"
										class='calender-overflow' dataType="date" dateFormat="dd/mm/yy"
										>
									</p-calendar>
								</td>
								<td>
									<button pButton type="button" *ngIf="userHasPermission('CAN_DELETE_PROJECT')"
										title="Delete" class="p-button-text" icon="pi pi-trash"
										(click)="deleteJobOrder(jobOrder.id, jobOrder.name)"></button>
								</td>
							</tr>
						</ng-container>
					</table>
					<div class="new-item-order" (click)="addNewItemLine()"><span><i class="pi pi-plus"></i> New order item</span></div>
				</div>
			</div>
		</div>
		<div class="p-formgroup-inline">
			<div class="p-col-12">
				<div class="">
					<h4>Job Order Details</h4>
				</div>
			</div>
			<div class="p-col-12">
				<label for="jobOrderNew_description">Description</label>
				<textarea type="text" pInputText id="jobOrderNew_description" name="jobOrderNew_description"
					[(ngModel)]="jobOrder_new.description" #description='ngModel' rows="8"></textarea>
			</div>
		</div>
		<hr />
		<ng-template pTemplate="footer">
			<button pButton pRipple label="Close" icon="pi pi-times" type="button" (click)="closeCreateJobOrder()"
				class="p-button-danger"></button>
			<button pButton pRipple label="Save" icon="pi pi-check" type="submit" class="p-button-oris"></button>
		</ng-template>
	</p-dialog>
</form>
<form #editJobOrderForm="ngForm" (ngSubmit)="editJobOrderForm.form.valid && updateJobOrder()">
	<p-dialog header="Edit JobOrder" [(visible)]="jobOrderEditModal" [modal]="true" [style]="{width: '800px'}"
		styleClass="p-fluid" [baseZIndex]="10000" [draggable]="false" [resizable]="false">
		<hr />
		<div class="p-formgroup-inline">
			<div class="p-col-6">
				<label for="jobOrderEdit_name">JobOrder Name</label>
				<input type="text" pInputText id="jobOrderEdit_name" name="jobOrderEdit_name"
					[(ngModel)]="jobOrder_edit.name" #name='ngModel' required />
				<div *ngIf="editJobOrderForm.submitted && name.invalid" class="error-login-info">
					<small *ngIf="name.errors?.required" class="p-error">Enter jobOrder name</small>
				</div>
			</div>
			<div class="p-col-3">
				<label for="jobOrderEditStartDate">Start Date</label>
				<p-calendar name="jobOrderEditStartDate"
					[style]="{'overflow-y':'visible', 'position':'fixed', 'z-index':999, 'width':'inherit'}"
					class='calender-overflow' dataType="date" dateFormat="dd/mm/yy"
					[(ngModel)]="jobOrder_edit.startDate">
				</p-calendar>
			</div>
			<div class="p-col-3">
				<label for="jobOrderEditEndDate">End Date</label>
				<p-calendar name="jobOrderEditEndDate"
					[style]="{'overflow-y':'visible', 'position':'fixed', 'z-index':999, 'width':'inherit'}"
					class='calender-overflow' dataType="date" dateFormat="dd/mm/yy" [(ngModel)]="jobOrder_edit.endDate">
				</p-calendar>
			</div>
		</div>
		<div class="p-formgroup-inline">
			<div class="p-col-3">
				<label for="jobOrderEdit_serviceInstance">Service Instance</label>
				<p-dropdown [options]="serviceInstanceList" [(ngModel)]="jobOrder_edit.serviceInstanceId"
					[panelStyle]="{'overflow-y':'auto'}" placeholder="Select service instance" optionLabel="name"
					name="serverInstance" [baseZIndex]="10001" required optionValue="id" #service_instance='ngModel'>
				</p-dropdown>
				<div *ngIf="editJobOrderForm.submitted && service_instance.invalid" class="error-login-info">
					<small *ngIf="service_instance.errors?.required" class="p-error">Select service instance</small>
				</div>
			</div>
		</div>
		<div class="p-formgroup-inline">
			<div class="p-col-12">
				<label for="jobOrderEdit_description">Description</label>
				<textarea type="text" pInputText id="jobOrderEdit_description" name="jobOrderEdit_description"
					[(ngModel)]="jobOrder_edit.description" #description='ngModel' rows="8"></textarea>
			</div>
		</div>
		<hr />
		<ng-template pTemplate="footer">
			<button pButton pRipple label="Close" icon="pi pi-times" type="button" (click)="closeEditJobOrder()"
				class="p-button-danger"></button>
			<button pButton pRipple label="Save" icon="pi pi-check" type="submit" class="p-button-oris"></button>
		</ng-template>
	</p-dialog>
</form>