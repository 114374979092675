import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { AppGlobals } from 'src/app/app.globals';
import { AppComponent } from '../../app.component';
import { AppMainComponent } from '../../app.main.component';
import * as $ from "jquery";
import { DashboardComponent } from 'src/app/modules/dashboard/dashboard.component';

@Component({
    selector: 'app-topbar',
    template: `
        <div class="layout-topbar">
                <div class="layout-topbar-left" >
                <!-- <a href="#" class="topbar-menu-button" (click)="appMain.onMenuButtonClick($event)" *ngIf="appMain.isOverlay() || appMain.isMobile()">
                    <i class="pi pi-bars"></i>
                </a> -->

                <!-- <a href="#" class="logo">
                    <img  [src]="'assets/layout/images/logo-'+ (app.colorScheme === 'light' ? 'dark' : 'light') + '.png'">
                </a>

                <a href="#">
                    <img  [src]="'assets/layout/images/appname-'+ (app.colorScheme === 'light' ? 'dark' : 'light') + '.png'" class="app-name"/>
                </a> -->
                    <div class="header-search-input" [ngClass]="{'search-area-open': mainSearchIsOpen === true}"><div style="position:relative"><div class="search-header-area">
                        <div class="search-area-close-icon">
                            <i class="pi pi-times" (click)="closeSearchMain()"></i>
                        </div>
                        <h2>Search in the entire database</h2>
                        <p>General search section</p>
                    </div>
                        <i class="pi pi-search" style=" display: none"></i>
                        <input class="input-search-area" type="text" id="input-search" [(ngModel)]="valueSearch" Placeholder="Search..." style="display: none"/>
                    </div>
                </div>
            </div>

            <app-menu></app-menu>

            <div class="layout-topbar-right">
                <ul class="layout-topbar-right-items">
                    <li style="margin-right:10px;" class="sidebar-menu-open-icon" (click)="openSidebarMenu($event)"><i class="pi pi-bars"></i></li>
                    <li style="margin-right:10px;" class="sidebar-logout-icon"  (click)="appMain.logout()"><i class="pi pi-sign-out"></i></li>
                    <li style="margin-right:10px;" class="sidebar-search-open-icon" (click)="openSearchMain()"><i class="pi pi-search"></i></li>
                </ul>
                <ul class="layout-topbar-right-items bottom-left-area">
                   
                    <li #profile class="profile-item" [ngClass]="{'active-topmenuitem':appMain.activeTopbarItem === profile}">
                        <ul class="fadeInDown" style="padding: 0 0">
                        <li role="menuitem">
                                <a href="/#/home">
                                    <i class="pi pi-fw pi-home"></i>
                                    <span>Dashboard</span>
                                </a>
                            </li>
                            <li role="menuitem">
                                <a href="javascript:void(0);" (click)="appMain.logout()">
                                    <i class="pi pi-fw pi-sign-out"></i>
                                    <span>Logout</span>
                                </a>
                            </li>
                        </ul>
                    </li>
                </ul>
                <div class="menu-wrapper-area" [ngClass]="{'closed-menu-wrapper':!getMenuStatus()}"></div>
                <div class="right-side-menu" [ngClass]="{'closed-menu':getMenuStatus()}">
                    <div class="menu-header">
                        <a href="#" (click)="closeSidebarMenu($event)"><i class="pi pi-times"></i></a>
                        <span>Menu</span>
                    </div>
                    <div class="menu-body">
                        <div class="menu-list-wrapper">
                            <div class="menu-title">
                                 <span (click)="closeSidebarMenu($event)" >Home</span>
                            </div>
                        </div>
                        <div class="menu-list-wrapper">
                            <div class="menu-title">
                                <span (click)="openMapSection('production')" >Production</span>
                            </div>
                            <ul>
                                <li (click)="openMapSection('productionsStatus')">
                                    <span class="item-description">Production Status</span>
                                </li>
                                <li (click)="openMapSection('productionOrders')">
                                    <span class="item-description">Orders</span>
                                </li>
                                <li (click)="openMapSection('qualityHandbook')">
                                    <span class="item-description">Quality Handbooks</span>
                                </li>
                                <li (click)="openMapSection('productionFitting')">
                                    <span class="item-description">Fittings</span>
                                </li>
                                <li (click)="openMapSection('productionTechPacks')">
                                    <span class="item-description">Tech Packs</span>
                                </li>
                                <li (click)="openMapSection('certificates')">
                                    <span class="item-description">Certificates</span>
                                </li>
                            </ul>
                        </div>
                        <div class="menu-list-wrapper">
                            <div class="menu-title">
                                <span (click)="openMapSection('samples')" >Samples</span>
                            </div>
                            <ul>
                                <li (click)="openMapSection('collections')">
                                    <span class="item-description">Collection Plans</span>
                                </li>
                                <li (click)="openMapSection('styleSheet')">
                                    <span class="item-description">Style sheets</span>
                                </li>
                                <li (click)="openMapSection('priceLists')">
                                    <span class="item-description">Price Lists</span>
                                </li>
                                <li (click)="openMapSection('pictures')">
                                    <span class="item-description">Pictures</span>
                                </li>
                                <li (click)="openMapSection('sampleFitting')">
                                    <span class="item-description">Fittings</span>
                                </li>
                                <li (click)="openMapSection('sampleFabricsAndTrims')">
                                    <span class="item-description">Fabrics and Trims</span>
                                </li>
                            </ul>
                        </div>
                        <div class="menu-list-wrapper">
                            <div class="menu-title">
                                <span (click)="openMapSection('offices')" >Administration</span>
                            </div>
                            <ul>
                                <li (click)="openMapSection('deliveryNotes')">
                                    <span class="item-description">Delivery Notes</span>
                                </li>
                                <li (click)="openMapSection('invoices')">
                                    <span class="item-description">Invoices</span>
                                </li>
                                <li (click)="openMapSection('agreements')">
                                <span class="item-description">Agreements</span>
                            </li>
                            </ul>
                        </div>
                        <div class="menu-list-wrapper menu-last-item">
                            <div class="menu-title">
                                <span (click)="openMapSection('documents')" >All Documents</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    `
})
export class AppTopbarComponent implements OnInit {
    public currentUserName: string;
    public sidebarMenuOpen: boolean = false;
    public mainSearchIsOpen: boolean = false;
    public dashboardComponent = null;
    public valueSearch: string = null;


    constructor(public app: AppComponent, public appMain: AppMainComponent, _dashboardComponent: DashboardComponent,
        public changeDetector: ChangeDetectorRef) {
        this.dashboardComponent = _dashboardComponent;
        this.getCurrentUserName();

    }

    ngOnInit() {
    }

    public openMapSection(location) {
        var locationObj = location ;
        (window as any).DashboardComponent.openSection(locationObj, true);
        //this.dashboardComponent.openSection(locationObj);
        this.closeSidebarMenu(null);
        this.changeDetector.detectChanges();
        this.changeDetector.markForCheck();
    }

    public openSearchMain() {
        var context = this;
        if (!context.mainSearchIsOpen) {
            context.mainSearchIsOpen = true;
        }
    }

    public closeSearchMain() {
        var context = this;
        if (context.mainSearchIsOpen) {
            context.mainSearchIsOpen = false;
            context.valueSearch = null;
        }
    }

    private getCurrentUserName() {
        this.currentUserName = AppGlobals.storage().get("username");
    }

    public openSidebarMenu(event) {
        this.sidebarMenuOpen = true;

        event.preventDefault();

    }

    public closeSidebarMenu(event) {
        this.sidebarMenuOpen = false;
        if (event != null) {
            event.preventDefault();
        }
    }

    public getMenuStatus() {
        return this.sidebarMenuOpen;
    }

}
