import { Component, ViewChild } from '@angular/core';
import { ConfirmationService, LazyLoadEvent, MessageService } from 'primeng/api';
import { Dropdown } from 'primeng/dropdown';
import { of } from 'rxjs';
import { catchError, finalize, tap } from "rxjs/operators";
import { AppGlobals } from 'src/app/app.globals';
import { AppBreadcrumbService } from 'src/app/core/breadcumb/app.breadcrumb.service';
import { Customer } from '../customers/domain/customer';
import { CustomerService } from '../customers/service/customerservice';
import { UserService } from '../users/service/userservice';
import { Enabled } from './domain/enabled';
import { ServiceInstance } from './domain/instance';
import { InstanceService } from './service/instanceservice';


@Component({
    templateUrl: './instance.component.html',
    styleUrls: ['./instance.component.scss']
})

export class InstanceComponent{
    private token: string;
    private event_lazy_local: LazyLoadEvent;
    public serviceInstance_new: ServiceInstance = {};
    public serviceInstance_edit: ServiceInstance = {};
    public serviceInstance_list: ServiceInstance[];
    public customersListFilter: Customer[];
    public customersList: Customer[];
    public enabledListFilter: Enabled[];
    public warningModal: boolean;
    public serviceInstanceCreateModal: boolean = false;
    public serviceInstanceEditModal: boolean = false;
    public serviceInstanceListLoading: boolean = true;
    public totalRecords: number;
    public itemsPerPage: number = 5;
   
    @ViewChild("dropdown_customers")
    dropdown_customers: Dropdown;
    @ViewChild("dropdown_enabled")
    dropdown_enabled: Dropdown;
    @ViewChild('dtServiceInstance', { static: true }) dtServiceInstance: any;
    

    constructor(
        private breadcrumbService: AppBreadcrumbService,
        private messageService: MessageService,
        private confirmationService: ConfirmationService,
        private customerService: CustomerService,
        private istanceService: InstanceService,
        private userService: UserService){

        let context = this; 
        context.token = sessionStorage.getItem('alkenium_Token').toString();
        context.breadcrumbService.setItems([AppGlobals.setBreadCrumb('Instances', '/instances', AppGlobals.userHasPermission("IS_CLIENT"))]);
        context.enabledListFilter = [];
        context.enabledListFilter.push({label:'Yes', value:true});
        context.enabledListFilter.push({label:'No', value:false});
        context.event_lazy_local = {} as LazyLoadEvent;
        context.setCustomersFilter();
    }

    private setCustomersFilter(){
        let context = this;
        context.customerService.getCustomerList(context.token, null, null).pipe(
            tap((response) => {
                    if(response.data != null)
                    {
                        context.customersListFilter = response.data.map((customer) => {
                            return {
                                id: customer.id,
                                companyName: customer.companyName
                            } as Customer
                        });
                    }
            }),catchError((response) => {return of();})).subscribe();
    }

    private setCustomersDropdown(){
        let context = this;
        context.customerService.getCustomerList(context.token, null, null).pipe(
                tap((response) => {
                    if(response.data != null)
                    {
                        context.customersList = response.data.map((customer) => {
                            return {
                                id: customer.id,
                                companyName: customer.companyName
                            } as Customer
                        });
                    }
                }),
                catchError((response) => {
                    return of();
            })).subscribe();
    }

    public userHasPermission(permission: string){
        return (permission != '' && permission != null ? (!AppGlobals.userHasPermission(permission) ? false : true) : true);
    }

    public createServiceInstance(){
        let context = this;
        context.serviceInstance_new = {isEnabled:true} as ServiceInstance;
        context.serviceInstanceCreateModal = true;
        context.setCustomersDropdown();
    }

    public editServiceInstance(id:string){
        let context = this;
        context.setCustomersDropdown();
        context.istanceService.getServiceInstance(context.token, id).pipe(
            tap((res) => {
                    if(res.data != null){
                        context.userService.getUser(context.token, res.data.userManagerId).pipe(
                            tap((response) => {
                                if(response.data != null){
                                    context.serviceInstance_edit = {
                                        id : res.data.id,
                                        name : res.data.name,
                                        guid : res.data.guid,
                                        customerId : res.data.customerId,
                                        startDate : new Date(res.data.startDate),
                                        endDate: new Date(res.data.endDate),
                                        isEnabled: res.data.isEnabled,
                                        managerName: response.data.name,
                                        managerSurname: response.data.surname,
                                        managerUsername: response.data.email,
                                        managerPassword: (Math.random() + 1).toString(36).substring(3),
                                        vatNumber: res.data.vatNumber,
                                        isSendServiceEnabled: res.data.isSendServiceEnabled,
                                        isReceiverServiceEnabled: res.data.isReceiverServiceEnabled
                                    }
                                }
                                context.serviceInstanceEditModal = true;
                            }),
                        catchError((response) => {return of();}),finalize(() => {})).subscribe();
                    }
                }),
            catchError((res) => {
                context.serviceInstanceEditModal = false;
                return of();
            }),finalize(() => {})).subscribe();
    }

    public closeCreateServiceInstance(){
        let context = this;
        context.serviceInstanceCreateModal = false;
    }

    public closeEditServiceInstance(){
        let context = this;
        context.serviceInstanceEditModal = false;
        context.serviceInstance_edit = {} as ServiceInstance;
    }

    public deleteServiceInstace(id:string, name:string){
        let context = this;
        context.confirmationService.confirm({
            message: "You are about to delete '"+name+"'. Are you sure?",
            header: "Warning",
            icon: "pi pi-warning",
            acceptLabel: "Yes",
            rejectLabel: "No",
            accept: () => {
                context.istanceService.deleteServiceInstance(context.token, id).pipe(
                        tap((res) => {
                            context.messageService.add({
                                severity: "success",
                                summary: "Success",
                                detail: res.message ?? "Success"
                            });
                        }),
                        catchError((res) => {
                            context.messageService.add({
                                severity: "error",
                                summary: "Error",
                                detail: res.error?.message ?? res.error.title
                            });
                            return of();
                        }),
                        finalize(() => {
                            context.getList(null);
                        })
                    )
                    .subscribe();
            },reject: () => {
                return;
            },
            rejectVisible: true
        });
    }

    public saveServiceInstance(){
        let context = this;
        context.istanceService.createServiceInstance(context.token, context.serviceInstance_new).pipe(
                tap((response) => {
                    if(response.data != null){
                        context.messageService.add({
                            severity:'success',
                            summary:'Success',
                            detail:response.message ?? "Success"
                        });
                        
                    }
                }),
                catchError((res) => {
                    context.messageService.add({
                        severity:'error',
                        summary:'Error',
                        detail: res.error?.message ?? res.error.title
                    })
                    context.serviceInstance_new = {} as ServiceInstance;
                    return of();
                }),
                finalize(() => {
                    context.getList(null);
                    context.serviceInstanceCreateModal = false;
                })
            )
            .subscribe();
    }
    
    public updateServiceInstance(){
        let context = this;
        context.istanceService.updateServiceInstance(context.token, context.serviceInstance_edit).pipe(
            tap((res) => {
                    if(res.data != null){
                        context.messageService.add({
                            severity:'success',
                            summary:'Success',
                            detail:res.message ?? "Success"
                        });

                        context.serviceInstanceEditModal = false;
                    }
                }),
                catchError((res) => {
                    context.messageService.add({
                        severity:'error',
                        summary:'Error',
                        detail: res.error?.message ?? res.error.title
                    })

                    return of();
                }),
                finalize(()=>{
                    context.getList(null);
                    context.serviceInstanceEditModal = false;
        })).subscribe();
    }

    public getList(event:LazyLoadEvent){
        let context = this;
        let customerId = context.dropdown_customers?.value ?? null;
        let isEnabled = context.dropdown_enabled?.value ?? null;

        if(event != null){ context.event_lazy_local = event;}
        context.istanceService.getServiceInstanceList(context.token, context.event_lazy_local, customerId, isEnabled).pipe(
                tap((res) => {
                    if(res.data != null){
                        let final = res.data.slice(context.event_lazy_local.first, (context.event_lazy_local.first + context.event_lazy_local.rows));
                        context.totalRecords = res.data.length;
                        context.serviceInstance_list = final.map((instance) => {
                            return{
                                id: instance.id,
                                name: instance.name,
                                guid: instance.guid,
                                customer: instance.customer,
                                startDate: instance.startDate,
                                endDate: instance.endDate,
                                isEnabled: instance.isEnabled,
                                vatNumber: instance.vatNumber,
                                isSendServiceEnabled: instance.isSendServiceEnabled,
                                isReceiverServiceEnabled: instance.isReceiverServiceEnabled,
                                creationTime: instance.creationTime
                            }
                        });
                    }
                    
                }),
                catchError((res) => {
                    return of();
                }),
                finalize(() => {
                    context.serviceInstanceListLoading = false;
            })).subscribe();
    }
}