<div class="p-col-12">
    <div *ngIf="collectionPlanExportLoading">
        <div class="p-fl-container"></div>
        <div class="p-d-flex p-fl-spinner">
            <p-progressSpinner styleClass="custom-spinner"></p-progressSpinner>
        </div>
    </div>
    <div class="card">
        <p-toolbar styleClass="p-mb-4">
            <div class="p-toolbar-group-left">
                <div class="p-d-flex p-flex-column p-flex-md-row">
                    <div style="margin-right: 2rem;">
                        <label for="filter_serviceIstances">Service istances</label>
                        <br />
                        <p-dropdown [options]="serviceInstancesFilter" #dropdown_serviceIstances
                            placeholder="Select service istance" (onChange)="getList(null)" [showClear]="true"
                            [style]="{'min-width':'100%'}" optionLabel="name" name="serviceIstance" optionValue="id">
                        </p-dropdown>
                    </div>
                </div>
            </div>
        </p-toolbar>
        <p-table #dtCollectionPlan [value]="collectionPlanList" dataKey="id" styleClass="p-datatable-oris" [rowHover]="true"
            [globalFilterFields]="['name', 'alkenium_number', 'customer', 'service_instance']" (onLazyLoad)="getList($event)"
            [lazy]="true" [totalRecords]="(totalRecords)" [rows]="(itemsPerPage)" [paginator]="true" [filterDelay]="0"
            [loading]="collectionPlanListLoading">
            <ng-template pTemplate="caption">
                <div class="p-d-flex p-flex-column p-flex-md-row p-jc-md-between table-header">
                    Collection Plan List
                    <span class="p-input-icon-left">
                        <i class="pi pi-search"></i>
                        <input pInputText type="text" (input)="dtCollectionPlan.filterGlobal($event.target.value, 'contains')" placeholder="Global Search" />
                    </span>
                </div>
            </ng-template>
            <ng-template pTemplate="header">
                <tr>
                    <th pSortableColumn="header.name">
                        <div class="p-d-flex p-jc-between p-ai-center">
                            Name
                            <p-sortIcon field="header.name"></p-sortIcon>
                        </div>
                    </th>
                    <th pSortableColumn="header.project">
                        <div class="p-d-flex p-jc-between p-ai-center">
                            Project
                            <p-sortIcon field="header.project"></p-sortIcon>
                        </div>
                    </th>
                    <th pSortableColumn="header.season">
                        <div class="p-d-flex p-jc-between p-ai-center">
                            Season
                            <p-sortIcon field="header.season"></p-sortIcon>
                        </div>
                    </th>
                    <th pSortableColumn="header.collection">
                        <div class="p-d-flex p-jc-between p-ai-center">
                            Collection
                            <p-sortIcon field="header.collection"></p-sortIcon>
                        </div>
                    </th>
                    <th style="width: 10.5rem"></th>
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-collectionPlan let-expanded="expanded">
                <tr class="p-selectable-row">
                    <td>
                        {{collectionPlan.header.name}}
                    </td>
                    <td>
                        {{collectionPlan.header.project}}
                    </td>
                    <td>
                        {{collectionPlan.header.season}}
                    </td>
                    <td>
                        {{collectionPlan.header.collection}}
                    </td>
                    <td>
                        <button pButton type="button" *ngIf="userHasPermission('CAN_VIEW_COLLECTIONPLANS_LIST')" title="Show details" icon="pi pi-info-circle"
                            class="p-button-rounded p-button-text" (click)="showCollectionPlan(collectionPlan.id)"></button>
                        <button pButton type="button" *ngIf="userHasPermission('CAN_VIEW_PRODUCT_LIST')" title="Show products"
                            icon="pi pi-list" class="p-button-rounded p-button-text" (click)="showCollectionPlanProducts(collectionPlan.id)"></button>
                        <button pButton type="button" *ngIf="userHasPermission('CAN_EXPORT_COLLECTIONPLAN')" title="Export Excel" class="p-button-text" icon="pi pi-file-excel"
                            (click)="exportCollectionPlan(collectionPlan.id, collectionPlan.header.name, exportFileType.EXCEL)"></button>
                        <button pButton type="button" *ngIf="userHasPermission('CAN_EXPORT_COLLECTIONPLAN')" title="Export Pdf"
                            class="p-button-text" icon="pi pi-file-pdf" (click)="exportCollectionPlan(collectionPlan.id, collectionPlan.header.name, exportFileType.PDF)"></button>
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td>No records found</td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>