import { ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, OnInit, QueryList, ViewChild, ViewChildren } from '@angular/core';
import { EventService } from '../../demo/service/eventservice';
import { LazyLoadEvent, MessageService, PrimeIcons } from 'primeng/api';
import { CustomerService } from '../../demo/service/customerservice';
import { AppBreadcrumbService } from 'src/app/core/breadcumb/app.breadcrumb.service';
import { DocumentCategoryService } from '../documents/service/documentcategoryservice';
import { catchError, finalize, tap } from 'rxjs/operators';
import { forkJoin, of } from 'rxjs';
import { Router } from '@angular/router';
import { AppGlobals } from 'src/app/app.globals';
import { DocumentService } from '../documents/service/documentservice';
import { CollectionPlanService } from '../collectionPlans/service/collectionPlan.service';
import { CollectionPlanDetailsService } from '../collectionPlanDetails/service/collectionPlanDetails.service';
import { CollectionPlanHeaderItem } from '../collectionPlanDetails/domain/collectionPlanHeaderItem';
import { Product } from '../products/domain/product';
import { ProductService } from '../products/service/product.service';
import { ExportFileType } from 'src/app/enum/exportFileType.enum';
import { ProductDetailService } from '../productDetails/service/productDetail.service';
import { ProjectService } from '../projects/service/projectservice';
import { ImgLoadedDirective } from './img.directive';
import { DomSanitizer } from '@angular/platform-browser';
import { Table } from 'primeng/table';

@Component({
    templateUrl: './dashboard.component.html',
    changeDetection: ChangeDetectionStrategy.OnPush,
    styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {
    public categories: any;
    public categoriesListModal: boolean = false;
    public projectsListModal: boolean = false;
    public collectionPlansListModal: boolean = false;
    public documentList: any = [];
    public collectionPlanListItemsData: any = [];
    public collectionPlanList: any = [];
    public collectionPlanItemsList: CollectionPlanHeaderItem[];
    public collectionPlanListLoading: boolean = true;
    public productExportLoading: boolean = false;
    public documentListCopy: any = [];
    public collectionPlanListCopy: any = [];
    public collectionPlanBackBtnVisible = false;
    public collectionPlanBackMode = 0;
    public projectList: any = [];
    public projectListCopy: any = [];
    public projectListLoading: boolean = false;
    public documentListLoading: boolean = true;
    public collectionPlansListLoading: boolean = true;
    public documentsListLoading: boolean = false;
    public totalItemsRecords: number;
    public categoriesView: boolean = false;
    public officesView: boolean = false;
    public styleSheetView: boolean = false;
    public hideCategoryBackBtn: boolean = false;
    public productListLoading: boolean = true;
    public isCollectionDetail: boolean = false;
    public collectionPlanLabel = '';
    public productsListVisible: boolean = false;
    public showProductDetailView: boolean = false;
    public totalRecords: number;
    public collectionPlanDetailId: string = null;
    public collectionPlanData: any = null;
    public documentsPath: string = 'https://hub.alkenium.it/assets/documents/';
    public activeModalTitle = { title: "", subtitle: "" };
    public collectionPlanDetailVisible: boolean = false;
    public collectionPlanItemsListLoading: boolean = true;
    public loadingImages = true;
    public lastOpenSection = null;
    public subcategorySectionThree = false;
    public productList: Product[];
    public exportFileType = ExportFileType;
    public productData: any;
    public modalImage = "modal-image.png";
    public modalColorClass = "black-modal-scheme";
    public documentsStatus = [
        { 'value': '0', 'label': 'Proposed' },
        { 'value': '1', 'label': 'Accepted' },
        { 'value': '2', 'label': 'Revision' }
    ];
    public itemsPerPage = 10;
    public itemsPerPageTable = 10;
    private dtCollectionPlanResetted = false;
    private dtCollectionPlanDetailsResetted = false;
    public imagesUrl = "https://hub.alkenium.it/assets/documents/";
    private currentInstaceId = null;
    public collectionPlanExportLoading: boolean = false;

    @ViewChild('dtCollectionPlanDetails', { static: false }) dtCollectionPlanDetails: Table;
    @ViewChild('dtCollectionPlan', { static: false }) dtCollectionPlan: Table;

    private event_lazy_local: LazyLoadEvent;
    private event_lazy_local_table: LazyLoadEvent;
    private event_lazy_local_table_products: LazyLoadEvent;
    @ViewChildren(ImgLoadedDirective) images: QueryList<ImgLoadedDirective>;
    private token: any;
    private categoryId: any;

    constructor(private customerService: CustomerService, private eventService: EventService,
        private documentCategoryService: DocumentCategoryService,
        private collectionPlanService: CollectionPlanService,
        private collectionPlanDetailService: CollectionPlanDetailsService,
        private documentService: DocumentService,
        private productDetailService: ProductDetailService,
        private productService: ProductService,
        private projectService: ProjectService,
        private sanitizer: DomSanitizer,
        private changeDetector: ChangeDetectorRef,
        private router: Router,
        private messageService: MessageService,
        private breadcrumbService: AppBreadcrumbService) {
        var context = this;
        context.token = sessionStorage.getItem('alkenium_Token').toString();
        context.categoryId = null;
        context.event_lazy_local = {} as LazyLoadEvent;
        context.event_lazy_local_table = {} as LazyLoadEvent;
        context.event_lazy_local_table_products = {} as LazyLoadEvent;
        context.breadcrumbService.setItems([
            AppGlobals.setBreadCrumb('Dashboard', '/home', false)
        ]);
        var configurations = AppGlobals.getAppConfigurations();
        if (configurations.azureStorageEnabled) {
            this.documentsPath = configurations.azureStorageDocumentsUrl;
            this.imagesUrl = configurations.azureStorageImagesUrl;
        }
        context.currentInstaceId = sessionStorage.getItem("serviceInstanceId");
    }

    public ngOnInit() {
        var context = this;
        window['DashboardComponent'] = [];
        window['DashboardComponent']['openSection'] = context.openSection.bind(context);
        (window as any).initMapNavigation();
    }

    public closelistModal() {
        var context = this;
        context.categoriesListModal = false;
        (window as any).hideLocationMap();
    }

    public closeProjectsListModal() {
        var context = this;
        context.projectsListModal = false;
        (window as any).hideLocationMap();
    }

    public closeCollectionPlansListModal() {
        var context = this;
        context.collectionPlansListModal = false;
        (window as any).hideLocationMap();
    }

    public getSelectedCollectionPlanDetail(id) {
        var context = this;
        context.collectionPlanBackBtnVisible = true;
        context.collectionPlanBackMode = 0;
        context.collectionPlanDetailVisible = true;
        context.dtCollectionPlanResetted = false;
        context.dtCollectionPlanDetailsResetted = false;
        context.activeModalTitle = { title: "Collection plan", subtitle: "details" };
        context.collectionPlanDetailService.getCollectionPlan(context.token, null, null, id)
            .pipe(
                tap((res) => {
                    if (res.data != null) {
                        context.collectionPlanData = res.data;
                    }
                    context.collectionPlansListLoading = false;
                    context.collectionPlanDetailId = context.collectionPlanData.id;
                    if(!context.dtCollectionPlanDetailsResetted){
                        setTimeout(() => {
                            context.dtCollectionPlan.reset();
                            context.dtCollectionPlan.first = 0;
                            context.dtCollectionPlanDetailsResetted = true;
                            this.dtCollectionPlan.onLazyLoad.emit(this.dtCollectionPlan.createLazyLoadMetadata()); 
                            this.changeDetector.markForCheck();
                          }, 100);
                    }
                    this.changeDetector.markForCheck();
                }),
                catchError((res) => {
                    context.collectionPlansListLoading = false;
                    return of();
                }),
                finalize(() => {
                    context.collectionPlansListLoading = false;
                })
            )
            .subscribe();

    }

    public getSelectedCollectionPlanProducts(id, collectionPlanName) {
        var context = this;
        context.collectionPlanLabel = collectionPlanName;
        context.collectionPlanDetailId = id;
        context.productsListVisible = true;
        context.collectionPlanBackBtnVisible = true;
        context.collectionPlanBackMode = 0;
        context.activeModalTitle = { title: "Products", subtitle: "list" };

    }

    public sanitize(url: string) {
        return this.sanitizer.bypassSecurityTrustUrl(url);
    }

    public getItemsList(event: LazyLoadEvent) {
        let context = this;
        
        if (event != null) {
            context.event_lazy_local_table = event;
        }

        let serviceInstanceId = null;

        context.collectionPlanItemsListLoading = true;
        context.collectionPlanDetailService.getCollectionPlanItemsList(context.token, context.event_lazy_local_table, serviceInstanceId, context.collectionPlanDetailId)
            .pipe(
                tap((res) => {
                    if (res.data != null) {
                        let slice = res.data.slice(context.event_lazy_local_table.first, (context.event_lazy_local_table.first + 10));
                         context.totalItemsRecords = res.data.length;

                        context.collectionPlanItemsList = slice.map((collectionPlan) => {
                            return collectionPlan;
                        });
                        
                        context.collectionPlanItemsListLoading = false;
                       
                        if(!context.dtCollectionPlanResetted){
                            setTimeout(() => {
                                context.dtCollectionPlanDetails.reset();
                                context.dtCollectionPlan.first = 0;
                                context.dtCollectionPlanResetted = true;
                                this.dtCollectionPlan.onLazyLoad.emit(this.dtCollectionPlan.createLazyLoadMetadata()); 
                                this.changeDetector.markForCheck();
                              }, 100);
                        }
                        this.changeDetector.markForCheck();
                        
                    }
                }),
                catchError((res) => {
                    if(res.error?.message != "'null' is not a valid 24 digit hex string."){
                        context.messageService.add({
                            severity: 'error',
                            summary: 'Error',
                            detail: res.error?.message ?? res.error.title
                        })
                    }
                    return of();
                }),
                finalize(() => {

                })
            )
            .subscribe();
    }

    public getList(event: LazyLoadEvent) {
        let context = this;
        if (event != null) {
            context.event_lazy_local = event;
        }

        let serviceInstanceId = null;

        context.collectionPlanListLoading = true;
        context.collectionPlanDetailService.getCollectionPlanDetailsList(context.token, context.event_lazy_local, serviceInstanceId, context.collectionPlanDetailId)
            .pipe(
                tap((res) => {
                    if (res.data != null) {
                        let slice = res.data.slice(context.event_lazy_local.first, (context.event_lazy_local.first + 10));
                        context.totalRecords = res.data.length;

                        context.collectionPlanListItemsData = slice.map((collectionPlan) => {
                            return {
                                season: collectionPlan.season,
                                productCategory: collectionPlan.productCategory,
                                project: collectionPlan.project,
                                collection: collectionPlan.collection,
                                line: collectionPlan.line,
                                lpCode: collectionPlan.lpCode,

                            }
                        });
                        context.collectionPlanListLoading = false;
                        this.changeDetector.markForCheck();
                    }
                }),
                catchError((res) => {
                    if(res.error?.message != "'null' is not a valid 24 digit hex string."){
                        context.messageService.add({
                            severity: 'error',
                            summary: 'Error',
                            detail: res.error?.message ?? res.error.title
                        })
                    }
                    return of();
                }),
                finalize(() => {

                })
            )
            .subscribe();
    }

    public getSelectedDocumentsList(categoryId, id) {
        var context = this;
        context.hideCategoryBackBtn = false;
        context.styleSheetView = false;

        context.documentCategoryService.getDocumentCategoriesFullList(context.token, "")
            .pipe(
                tap((res) => {
                    if (res.data != null) {
                        
                        var documentSelected = res.data.filter(function (element) {
                            if (element.id == id) {
                                return element;
                            }

                        });

                        if(documentSelected[0].name == "Style Sheets PDF" || documentSelected[0].name == "Project Sheets PDF" ||  documentSelected[0].name == "Price Lists"  || documentSelected[0].name == "Pictures" || documentSelected[0].name == "Fabrics & Trims" || documentSelected[0].name =="Samples Fittings"){
                            context.styleSheetView = true;
                            context.officesView = false;
                        }

                        context.activeModalTitle = { title: documentSelected[0].name, subtitle: "" };
                        this.changeDetector.markForCheck();

                    }
                }),
                catchError((res) => {
                    return of();
                }),
                finalize(() => {
                })
            )
            .subscribe();


      
        context.getDocumentsList(categoryId, id);
    }

    public openDocumentsList(id) {
        var context = this;
        context.router.navigate(["/documents/" + id]);
    }

    public goBackToCategories() {
        var context = this;
        context.subcategorySectionThree = false;
        context.categoriesView = true;
        context.activeModalTitle = { title: "Documents", subtitle: "list" };
        context.changeDetector.markForCheck();
        context.getDocumentsCategoriesList();
    }

    public goBackToStyleCategories() {
        var context = this;
        context.documentList = [];
        context.documentListCopy = [];
        context.categoriesListModal = true;
        context.activeModalTitle = { title: "Samples", subtitle: "list" };
        context.styleSheetView = true;
        context.getSamplesCategoriesList();
    }

    public goBackToStyleSheetCategories(){
        var context = this;
        context.openSection('styleSheet',false);

    }

    public goBackToOfficesCategories() {
        var context = this;
        context.documentList = [];
        context.documentListCopy = [];
        context.categoriesListModal = true;
        context.modalColorClass = "black-modal-scheme";
        context.modalImage = "29.jpg";
        context.changeDetector.markForCheck();
        context.activeModalTitle = { title: "Administration", subtitle: "" };
        context.styleSheetView = false;
        context.officesView = true;
        context.getDocumentsOfficesList();
    }

    public goBackToProductionCategories() {
        var context = this;
        context.documentList = [];
        context.documentListCopy = [];
        context.categoriesListModal = true;
        context.modalColorClass = "gold-modal-scheme";
        context.modalImage = "22.jpeg";
        context.changeDetector.markForCheck();
        context.activeModalTitle = { title: "Production", subtitle: "" };
        context.styleSheetView = false;
        context.officesView = true;
        context.getAministrationCategoriesList();
    }

    public goBackToSamplesCategories() {
        var context = this;
        context.collectionPlansListModal = false;
        context.documentList = [];
        context.documentListCopy = [];
        context.categoriesListModal = true;
        context.modalColorClass = "grey-modal-scheme";
        context.modalImage = "modal-image.png";
        context.changeDetector.markForCheck();
        context.activeModalTitle = { title: "Samples", subtitle: "" };
        context.getSamplesCategoriesList();
        context.styleSheetView = false;
        context.officesView = true;
        context.getSamplesCategoriesList();
    }

    public projectListModelVisible() {
        return this.projectsListModal;
    }

    public openSection(location, menuCall) {
        var context = this;
        context.isCollectionDetail = false;
        context.categoriesListModal = false; 
        switch (location) {
            case 'documents':
                context.modalImage = "24.jpeg";
                context.changeDetector.markForCheck();
                context.documentList = [];
                context.modalColorClass = "white-modal-scheme";
                context.documentListCopy = [];
                context.lastOpenSection = 'documents';
                context.hideCategoryBackBtn = true;
                context.categoriesListModal = true;
                context.activeModalTitle = { title: "Documents", subtitle: "list" };
                context.styleSheetView = false;
                context.officesView = false;
                context.getDocumentsCategoriesList();
                break;
            case 'styleSheet':
                context.documentList = [];
                context.documentListCopy = [];
                context.modalColorClass = (context.lastOpenSection != 'documents' ? "grey-modal-scheme" :  "white-modal-scheme" );
                context.hideCategoryBackBtn = true;
                context.modalImage = (context.lastOpenSection != 'documents' ? "modal-image.png": "24.jpeg");
                context.lastOpenSection = (context.lastOpenSection == 'documents' ? "documents" : 'samples');
                context.subcategorySectionThree = (context.lastOpenSection == 'documents' ? true : false);
                context.categoriesListModal = true;
                context.categoriesView = false;
                context.activeModalTitle = { title: "Style sheet", subtitle: "list" };
                context.styleSheetView = true;
                context.officesView = false;
                context.changeDetector.markForCheck();
                context.getDocumentsStyleSheetList(false);
                break;
            case 'collections':
                context.collectionPlanList = [];
                context.modalImage = "4.jpeg";
                context.modalColorClass = "grey-modal-scheme";
                context.changeDetector.markForCheck();
                context.collectionPlanListCopy = [];
                context.collectionPlansListModal = true;
                context.hideCategoryBackBtn = true;
                context.showProductDetailView = false;
                context.lastOpenSection = 'samples';
                context.collectionPlanBackBtnVisible = false;
                context.collectionPlanBackMode = 0;
                context.productsListVisible = false;
                context.styleSheetView = false;
                context.isCollectionDetail = true;
                context.officesView = false;
                context.activeModalTitle = { title: "Collection Plans", subtitle: "list" };
                context.getCollectionPlansList();
                break;
            /*case 'projects':
                    context.projectList = [];
                    context.projectListCopy = [];
                    context.projectsListModal = true;
                    context.activeModalTitle = {title: "Projects", subtitle:"list"};
                    context.getProjectsList();
                    break;*/
            case 'production':
                context.documentList = [];
                context.documentListCopy = [];
                context.categoriesListModal = true;
                context.styleSheetView = false;
                context.officesView = true;
                context.lastOpenSection = 'production';
                context.modalColorClass = "gold-modal-scheme";
                context.modalImage = "22.jpeg";
                context.changeDetector.markForCheck();
                context.activeModalTitle = { title: "Production", subtitle: "" };
                context.getAministrationCategoriesList();
                break;
            case 'samples':
                context.documentList = [];
                context.documentListCopy = [];
                context.categoriesListModal = true;
                context.styleSheetView = false;
                context.lastOpenSection = 'samples';
                context.modalColorClass = "grey-modal-scheme";
                context.modalImage = "modal-image.png";
                context.changeDetector.markForCheck();
                context.officesView = true;
                context.activeModalTitle = { title: "Samples", subtitle: "" };
                context.getSamplesCategoriesList();
                break;
            case 'productionOrders':
                context.documentList = [];
                context.documentListCopy = [];
                context.modalColorClass = "gold-modal-scheme";
                context.modalImage = "22.jpeg";
                context.lastOpenSection = 'production';
                context.categoriesListModal = true;
                context.hideCategoryBackBtn = true;
                context.styleSheetView = false;
                context.officesView = true;
                context.activeModalTitle = { title: "Production Orders", subtitle: "list" };
                context.getDocumentsList("PRODUCTION_ORDERS");
                break;
            case 'productionFitting':
                context.documentList = [];
                context.documentListCopy = [];
                context.modalColorClass = "gold-modal-scheme";
                context.hideCategoryBackBtn = true;
                context.lastOpenSection = 'production';
                context.categoriesListModal = true;
                context.officesView = true;
                context.modalImage = "22.jpeg";
                context.changeDetector.markForCheck();
                context.activeModalTitle = { title: "Production Fittings", subtitle: "list" };
                context.getDocumentsList("PRODUCTION_FITTING");
                break;
            case 'productionTechPacks':
                context.documentList = [];
                context.documentListCopy = [];
                context.modalColorClass = "gold-modal-scheme";
                context.officesView = true;
                context.modalImage = "22.jpeg";
                context.lastOpenSection = 'production';
                context.changeDetector.markForCheck();
                context.hideCategoryBackBtn = true;
                context.categoriesListModal = true;
                context.activeModalTitle = { title: "Production tech packs", subtitle: "list" };
                context.getDocumentsList("PRODUCTION_TECH_PACKS");
                break;
            case 'certificates':
                context.documentList = [];
                context.documentListCopy = [];
                context.hideCategoryBackBtn = true;
                context.categoriesListModal = true;
                context.styleSheetView = false;
                context.modalColorClass = "gold-modal-scheme";
                context.lastOpenSection = 'production';
                context.officesView = true;
                context.modalImage = "21.jpeg";
                context.changeDetector.markForCheck();
                context.activeModalTitle = { title: "Certificates", subtitle: "list" };
                context.getDocumentsList("CERTIFICATES");
                break;
            case 'projects':
                context.documentList = [];
                context.documentListCopy = [];
                context.hideCategoryBackBtn = true;
                context.styleSheetView = false;
                context.officesView = false;
                context.categoriesListModal = true;
                context.activeModalTitle = { title: "Projects", subtitle: "list" };
                context.getDocumentsList("PROJECTS");
                break;
            case 'offices':
                context.documentList = [];
                context.modalImage = "29.jpg";
                context.modalColorClass = "black-modal-scheme";
                context.changeDetector.markForCheck();
                context.documentListCopy = [];
                context.lastOpenSection = 'offices';
                context.categoriesListModal = true;
                context.styleSheetView = false;
                context.officesView = true;
                context.activeModalTitle = { title: "Administration", subtitle: "" };
                context.getDocumentsOfficesList();
                break;
            case 'priceLists':
                context.documentList = [];
                context.documentListCopy = [];
                context.modalColorClass = "grey-modal-scheme";
                context.modalImage = "modal-image.png";
                context.lastOpenSection = 'samples';
                context.hideCategoryBackBtn = true;
                context.categoriesListModal = true;
                context.styleSheetView = true;
                context.officesView = false;
                context.activeModalTitle = { title: "Price lists", subtitle: "list" };
                context.changeDetector.markForCheck();
                context.getDocumentsList("PRICE_LISTS");
                break;
            case "invoices":
                context.documentList = [];
                context.documentListCopy = [];
                context.modalColorClass = "black-modal-scheme";
                context.modalImage = "29.jpg";
                context.lastOpenSection = 'offices';
                context.hideCategoryBackBtn = true;
                context.categoriesListModal = true;
                context.styleSheetView = false;
                context.officesView = true;
                context.activeModalTitle = { title: "Invoices", subtitle: "list" };
                context.getDocumentsByCategory("Invoices");
                break;
            case "agreements":
                    context.documentList = [];
                    context.documentListCopy = [];
                    context.modalColorClass = "black-modal-scheme";
                    context.modalImage = "29.jpg";
                    context.lastOpenSection = 'offices';
                    context.hideCategoryBackBtn = true;
                    context.categoriesListModal = true;
                    context.styleSheetView = false;
                    context.officesView = true;
                    context.activeModalTitle = { title: "Agreements", subtitle: "list" };
                    context.getDocumentsByCategory("Agreements");
                    break;
            case "deliveryNotes":
                context.documentList = [];
                context.documentListCopy = [];
                context.hideCategoryBackBtn = true;
                context.modalColorClass = "black-modal-scheme";
                context.lastOpenSection = 'offices';
                context.modalImage = "29.jpg";
                context.categoriesListModal = true;
                context.styleSheetView = false;
                context.officesView = true;
                context.activeModalTitle = { title: "Delivery notes", subtitle: "list" };
                context.getDocumentsByCategory("Delivery Notes");
                break;
            case "sampleFabricsAndTrims":
                context.documentList = [];
                context.documentListCopy = [];
                context.modalColorClass = "grey-modal-scheme";
                context.modalImage = "modal-image.png";
                context.hideCategoryBackBtn = true;
                context.lastOpenSection = 'samples';
                context.categoriesListModal = true;
                context.styleSheetView = true;
                context.officesView = false;
                context.activeModalTitle = { title: "Fabrics and Trims", subtitle: "" };
                context.getDocumentsByCategory("Fabrics & Trims");
                break;
            case "productionsStatus":
                context.documentList = [];
                context.documentListCopy = [];
                context.hideCategoryBackBtn = true;
                context.officesView = true;
                context.modalColorClass = "gold-modal-scheme";
                context.lastOpenSection = 'production';
                context.modalImage = "22.jpeg";
                context.changeDetector.markForCheck();
                context.categoriesListModal = true;
                context.styleSheetView = false;
                context.activeModalTitle = { title: "Production Status", subtitle: "list" };
                context.getDocumentsByCategory("Production Status");
                break;
            case 'pictures':
                context.documentList = [];
                context.documentListCopy = [];
                context.modalColorClass = "grey-modal-scheme";
                context.modalImage = "modal-image.png";
                context.lastOpenSection = 'samples';
                context.hideCategoryBackBtn = true;
                context.categoriesListModal = true;
                context.styleSheetView = true;
                context.officesView = false;
                context.activeModalTitle = { title: "Pictures", subtitle: "list" };
                context.getDocumentsList("PICTURES");
                break;
            case 'sampleFitting':
                context.documentList = [];
                context.documentListCopy = [];
                context.modalColorClass = "grey-modal-scheme";
                context.modalImage = "26.jpeg";
                context.changeDetector.markForCheck();
                context.lastOpenSection = 'samples';
                context.hideCategoryBackBtn = true;
                context.categoriesListModal = true;
                context.styleSheetView = false;
                context.officesView = true;
                context.activeModalTitle = { title: "Samples fittings", subtitle: "list" };
                context.getDocumentsList("SAMPLE_FITTING");
                break;
            case 'qualityHandbook':
                context.documentList = [];
                context.documentListCopy = [];
                context.hideCategoryBackBtn = true;
                context.modalColorClass = "gold-modal-scheme";
                context.categoriesListModal = true;
                context.modalImage = "23.jpeg";
                context.lastOpenSection = 'production';
                context.changeDetector.markForCheck();
                context.styleSheetView = false;
                context.officesView = true;
                context.activeModalTitle = { title: "Quality Handbooks", subtitle: "list" };
                context.getDocumentsList("QUALITY_HANDBOOK");
                break;
            default:
                break;
        }

        if (menuCall == true) {
            (window as any).goToLocationMap(location.id);
        }

        //this.changeDetector.detectChanges();
        this.changeDetector.markForCheck();
    }

    public getSelectedProjectDetail(id) {

    }

    public filterCategoriesList(event) {
        var context = this;
        context.documentList = context.documentListCopy.filter(function (element) {
            return (typeof element.originalFileName === 'undefined' ? element.name.toLowerCase().includes(event.target.value.toLowerCase()) : element.originalFileName.toLowerCase().includes(event.target.value.toLowerCase()));
        });
    }

    public filterProjectsList(event) {
        var context = this;
        context.projectList = context.projectListCopy.filter(function (element) {
            return element.name.toLowerCase().includes(event.target.value.toLowerCase());
        });
    }

    public filterCollectionPlansList(event) {
        var context = this;

        context.collectionPlanList = context.collectionPlanListCopy.filter(function (element) {
            if (typeof element.header != 'undefined') {
                return (element.header.name.toLowerCase().includes(event.target.value.toLowerCase()) || element.header.collection.toLowerCase().includes(event.target.value.toLowerCase()));
            } else {
                return (element.name.toLowerCase().includes(event.target.value.toLowerCase()) || element.description.toLowerCase().includes(event.target.value.toLowerCase()));
            }

        });
    }

    public downloadDocument(document) {
        var context = this;
        window.open(context.documentsPath + document.blobFilePath, '_blank');
    }

    public getCollectionPlansList() {
        var context = this;
        context.collectionPlansListLoading = true;
        context.collectionPlanDetailVisible = false;
        context.collectionPlanList = [];
        context.collectionPlanListCopy = [];
        context.collectionPlanService.getCollectionPlanList(context.token, context.event_lazy_local, null)
            .pipe(
                tap((res) => {
                    if (res.data != null) {
                        context.collectionPlanList = res.data;
                        context.collectionPlanListCopy = context.collectionPlanList;
                    }
                    context.collectionPlansListLoading = false;
                    this.changeDetector.markForCheck();
                }),
                catchError((res) => {
                    context.collectionPlansListLoading = false;
                    return of();
                }),
                finalize(() => {
                    context.collectionPlansListLoading = false;
                })
            )
            .subscribe();
    }

    public userHasPermission(permission: string) {
        return (permission != '' && permission != null ? (!AppGlobals.userHasPermission(permission) ? false : true) : true);
    }

    public exportFile(content: string, product: Product, typeExport: ExportFileType) {
        let context = this;
        const linkSource = "data:application/octet-stream;base64," + content;
        const downloadLink = document.createElement("a");
        const fileName =
            "PRODUCT SHEET " +
            (typeof product.collection !== "undefined" && product.collection !== null
                ? product.collection.replace(/[^a-zA-Z0-9 ]/g, '') + " "
                : "") +
            (typeof product.code !== "undefined" && product.code !== null
                ? product.code.replace(/[^a-zA-Z0-9 ]/g, '')
                : "") +
            "." + context.getExtensionFile(typeExport);
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
    }

    public exportCollectionPlanSimpleFile(content: string, name: string, typeExport: ExportFileType, versionNumber: number) {
        let context = this;
        const linkSource = "data:application/octet-stream;base64," + content;
        const downloadLink = document.createElement("a");
        const fileName = "COLLECTION PLAN ALKENIUM " + name.replace(/[^a-zA-Z0-9 ]/g, '') + "_" + versionNumber + "." + context.getExtensionFile(typeExport);
        downloadLink.href = linkSource;
        downloadLink.download = fileName;
        downloadLink.click();
    }

    public getExtensionFile(typeExport: ExportFileType) {
        let context = this;
        if (typeExport === context.exportFileType.EXCEL) {
            return "xlsx";
        } else if (typeExport === context.exportFileType.PDF) {
            return "pdf";
        } else {
            return "xlsx";
        }
    }

    public goBackTo() {
        var context = this;
        if (context.collectionPlanBackMode == 1) {
            context.collectionPlanBackMode = 0;
            context.showProductDetailView = false;
            context.productsListVisible = true;
            context.activeModalTitle = { title: "Products", subtitle: "list" };
        } else {
            context.productsListVisible = true;
            context.collectionPlanBackBtnVisible = true;
            context.collectionPlanBackMode = 0;
            context.collectionPlanList = [];
            context.collectionPlanListCopy = [];
            context.collectionPlansListModal = true;
            context.showProductDetailView = false;
            context.collectionPlanBackBtnVisible = false;
            context.collectionPlanBackMode = 0;
            context.productsListVisible = false;
            context.activeModalTitle = { title: "Collection Plans", subtitle: "list" };
            context.getCollectionPlansList();
        }
    }

    public showProduct(id: string) {
        var context = this;
        context.activeModalTitle = { title: "Product", subtitle: "details" };
        context.collectionPlanBackBtnVisible = true;
        context.collectionPlanBackMode = 1;
        context.showProductDetailView = true;
        context.productsListVisible = false;
        context.getProductData(id);
    }

    public getProductData(productId: string) {
        let context = this;
        context.loadingImages = true;
        context.productDetailService.getProductData(context.token, null, productId)
            .pipe(
                tap((res) => {
                    if (res.data != null) {
                        var context = this;
                        context.productData = res.data;
                        setTimeout(function () {
                            if (context.images.length > 0) {
                                forkJoin(context.images.map(imgDir => imgDir.loaded)).subscribe(() => {
                                    context.loadingImages = false;
                                });
                            } else {
                                context.loadingImages = false;
                            }
                            context.changeDetector.markForCheck();
                        }, 1000);
                        this.changeDetector.markForCheck();
                    }
                }),
                catchError(() => {
                    return of();
                })
            )
            .subscribe();
    }

    public getProjectsList() {
        let context = this;
        let serviceInstanceId = null;

        context.projectListLoading = true;
        context.projectService.getProjectList(context.token, context.event_lazy_local, serviceInstanceId)
            .pipe(
                tap((res) => {
                    if (res.data != null) {
                        context.totalRecords = res.data.length;
                        context.projectList = res.data.map((project) => {
                            return {
                                id: project.id,
                                name: project.name,
                                alkeniumNumber: project.alkeniumNumber,
                                startTime: project.startTime,
                                customer: project.customer,
                                serviceInstance: project.instance,
                                endTime: project.endTime
                            }
                        });
                        context.projectListLoading = false;
                        context.projectListCopy = context.projectList;
                        this.changeDetector.markForCheck();
                    }
                }),
                catchError((res) => {
                    if(res.error?.message != "'null' is not a valid 24 digit hex string."){
                        context.messageService.add({
                            severity: 'error',
                            summary: 'Error',
                            detail: res.error?.message ?? res.error.title
                        })
                    }
                    
                    return of();
                }),
                finalize(() => {

                })
            )
            .subscribe();
    }

    public exportProduct(id: string, product: Product, typeExport: ExportFileType) {
        let context = this;
        let serviceInstanceId = null;
        context.collectionPlanExportLoading = true;

        context.productService.exportProduct(context.token, serviceInstanceId, id, typeExport).pipe(
            tap((response) => {
                if (typeof response.data !== 'undefined' && typeof response.data.content !== 'undefined' && response.data.content !== '') {
                    context.exportFile(response.data.content, product, typeExport);
                    context.messageService.add({
                        severity: "success",
                        summary: "Success",
                        detail: response.message ?? "Exported the file successfully"
                    });
                } else {
                    context.messageService.add({
                        severity: "error",
                        summary: "Error",
                        detail: "Could not export the file",
                    });
                    return of();
                }
                context.changeDetector.markForCheck();
            }), catchError((error) => {
                if(error?.message != "'null' is not a valid 24 digit hex string."){
                    context.messageService.add({
                        severity: "error",
                        summary: "Error",
                        detail: error.message ?? error.error.title
                    });
            }
                return of();
            }), finalize(() => {
                context.collectionPlanExportLoading = false;
                context.changeDetector.markForCheck();
            })).subscribe();
    }

    public getProductsList(event: LazyLoadEvent) {
        let context = this;
        context.productsListVisible = true;
        if (event != null) {
            context.event_lazy_local = event;
        }
        let serviceInstanceId = null;

        context.productListLoading = true;
        context.productService.getProductList(context.token, context.event_lazy_local, serviceInstanceId, context.collectionPlanDetailId)
            .pipe(
                tap((res) => {
                    if (res.data != null) {

                       
                        context.productList = res.data.map((product) => {
                            return {
                                id: product.id,
                                serviceInstanceId: product.serviceInstanceId,
                                externalId: product.externalId,
                                transactionId: product.transactionId,
                                user: product.user,
                                project: product.project,
                                season: product.season,
                                collection: product.collection,
                                family: product.family,
                                productCategory: product.productCategory,
                                line: product.line,
                                management: product.management,
                                lpCode: product.lpCode,
                                versionLPCode: product.versionLPCode,
                                deleted: product.deleted,
                                sid: product.sid,
                                code: product.code,
                                description: product.description,
                                productName: product.productName,
                                descriptionEng: product.descriptionEng,
                                productType: product.productType,
                                subject: product.subject,
                                theme: product.theme,
                                usedFor: product.usedFor,
                                group: product.group,
                                technicalGroup: product.technicalGroup,
                                labelNr: product.labelNr,
                                cost: product.cost,
                                note: product.note,
                                originalStyle: product.originalStyle,
                                originalSeason: product.originalSeason,
                                originalLine: product.originalLine,
                                um: product.um,
                                umBom: product.umBom,
                                umBuy: product.umBuy,
                                umSell: product.umSell,
                                confSite: product.confSite,
                                embroiderySite: product.embroiderySite,
                                sizeRange: product.sizeRange,
                                protoSize: product.protoSize,
                                weight: product.weight,
                                intracode: product.intracode,
                                grpComposition: product.grpComposition,
                                componentTreatments: product.componentTreatments,
                                imagesShetch: product.imagesShetch,
                                imagesProduct: product.imagesProduct,
                                lstFitting: product.lstFitting,
                                colors: product.colors,
                                versionNumber: product.versionNumber,
                                isActive: product.isActive,
                                styleImageUrl: product.styleImageUrl,
                            }
                        });

                        let slice = context.productList.slice(context.event_lazy_local.first, (context.event_lazy_local.first + 10));
                        context.totalRecords = context.productList.length;

                        context.productList = slice.map((product) => {
                            return product;
                        });

                        context.productListLoading = false;
                        this.changeDetector.markForCheck();
                    }
                }),
                catchError((res) => {
                    if(res.error?.message != "'null' is not a valid 24 digit hex string."){
                        context.messageService.add({
                            severity: 'error',
                            summary: 'Error',
                            detail: res.error?.message ?? res.error.title
                        })
                    }
                    return of();
                }),
                finalize(() => {
                })
            )
            .subscribe();
    }

    public getDocumentsOfficesList() {
        var context = this;
        context.getOfficeCategoriesList();
    }

    public getSamplesCategoriesList() {
        var context = this;
        context.categoriesView = true;
        context.documentsListLoading = true;
       
        context.documentCategoryService.getDocumentCategoriesFullList(context.token, "")
            .pipe(
                tap((res) => {
                    if (res.data != null) {
                       var categoriesList = ['Collection Plans', 'Style Sheets', 'Price Lists', 'Pictures', 'Samples Fittings', 'Fabrics & Trims']

                        context.documentList = res.data.filter(function (element) {
                            if (categoriesList.includes(element.name)) {
                                return element;
                            }
                        });

                        context.documentList.push({
                            id: "",
                            name: "Collection Plans"
                        });


                        for (let index = 0; index < context.documentList.length; index++) {
                           
                            switch (context.documentList[index].name) {
                                case 'Collection Plans':
                                    context.documentList[index].order = 1;
                                    context.documentList[index].image = 4;
                                break;
                                case 'Style Sheets':
                                    context.documentList[index].order = 2;
                                    context.documentList[index].image = 28;
                                break;
                                case 'Price Lists':
                                    context.documentList[index].order = 3;
                                    context.documentList[index].image = 7;
                                break;
                                case 'Pictures':
                                    context.documentList[index].order = 4;
                                    context.documentList[index].image = 5;
                                break;
                                case 'Samples Fittings':
                                    context.documentList[index].order = 5;
                                    context.documentList[index].name = "Fittings";
                                    context.documentList[index].image = 26;
                                break;
                                case 'Fabrics & Trims':
                                    context.documentList[index].order = 5;
                                    context.documentList[index].image = 2;
                                    context.documentList[index].name = "Fabrics and Trims";
                                break;
                            
                                default:
                                    break;
                            }
                            
                        }

                        

                        context.documentList.sort((a, b) => a.order-b.order);

                        context.documentListCopy = context.documentList;


                    }

                    context.categoriesView = true;
                    //context.officesView = true;
                    context.styleSheetView = true;
                    context.documentsListLoading = false;
                    this.changeDetector.markForCheck();
                }),
                catchError((res) => {
                    context.documentsListLoading = false;
                    return of();
                }),
                finalize(() => {
                    context.documentsListLoading = false;
                })
            )
            .subscribe();

       
    }

    public getAministrationCategoriesList() {
        var context = this;
        context.categoriesView = true;
        context.documentsListLoading = true;
       
        context.documentCategoryService.getDocumentCategoriesFullList(context.token, "")
            .pipe(
                tap((res) => {
                    if (res.data != null) {
                       var categoriesList = ['Production Status', 'Production Orders', 'Quality Handbooks', 'Production Fittings', 'Production Tech Packs', 'Certificates']

                        context.documentList = res.data.filter(function (element) {
                            if (categoriesList.includes(element.name)) {
                                return element;
                            }
                        });

                        for (let index = 0; index < context.documentList.length; index++) {
                           
                            switch (context.documentList[index].name) {
                                case 'Production Status':
                                    context.documentList[index].order = 1;
                                    context.documentList[index].image = 14;
                                break;
                                case 'Production Orders':
                                    context.documentList[index].order = 2;
                                    context.documentList[index].name = "Orders";
                                    context.documentList[index].image = 8;
                                break;
                                case 'Quality Handbooks':
                                    context.documentList[index].order = 3;
                                    context.documentList[index].image = 23;
                                break;
                                case 'Production Fittings':
                                    context.documentList[index].order = 4;
                                    context.documentList[index].name = "Fittings";
                                    context.documentList[index].image = 26;
                                break;
                                case 'Production Tech Packs':
                                    context.documentList[index].order = 5;
                                    context.documentList[index].name = "Tech Packs";
                                    context.documentList[index].image = 27;
                                break;
                                case 'Certificates':
                                    context.documentList[index].order = 5;
                                    context.documentList[index].image = 21;
                                break;
                            
                                default:
                                    break;
                            }
                            
                        }

                        

                        context.documentList.sort((a, b) => a.order-b.order);

                        context.documentListCopy = context.documentList;
                    }

                    context.categoriesView = true;
                    context.officesView = true;
                    context.documentsListLoading = false;
                    this.changeDetector.markForCheck();
                }),
                catchError((res) => {
                    context.documentsListLoading = false;
                    return of();
                }),
                finalize(() => {
                    context.documentsListLoading = false;
                })
            )
            .subscribe();

       
    }

    public getDocumentsStyleSheetList(reset = true) {
        var context = this;

        if(reset){
            context.categoriesView = true;
            context.styleSheetView = true;
        }


    context.documentCategoryService.getDocumentCategoriesFullList(context.token, "")
            .pipe(
                tap((res) => {
                    if (res.data != null) {

                        var categoriesList = ['Style Sheets PDF', 'Project Sheets PDF'];

                        context.documentList = res.data.filter(function (element) {
                            if (categoriesList.includes(element.name)) {
                                return element;
                            }
                        });
                        context.styleSheetView = true;

                        for (let index = 0; index < context.documentList.length; index++) {
                           
                            switch (context.documentList[index].name) {
                                
                                case 'Project Sheets PDF':
                                    context.documentList[index].order = 13;
                                    context.documentList[index].image = 31;
                                break;
                                case 'Style Sheets PDF':
                                    context.documentList[index].order = 14;
                                    context.documentList[index].image = 32;
                                break;
                               
                            
                                default:
                                    break;
                            }
                            
                        }

                        context.documentListCopy = context.documentList;
                    }
                    context.documentsListLoading = false;
                    this.changeDetector.markForCheck();
                }),
                catchError((res) => {
                    context.documentsListLoading = false;
                    return of();
                }),
                finalize(() => {
                    context.documentsListLoading = false;
                })
            )
            .subscribe();


    }

    public getDocumentsCategoriesList() {
        var context = this;
        context.categoriesView = true;
        context.documentsListLoading = true;
        context.documentCategoryService.getDocumentCategoriesFullList(context.token, "")
            .pipe(
                tap((res) => {
                    if (res.data != null) {
                        context.documentList = res.data;


                        for (let index = 0; index < context.documentList.length; index++) {
                           
                            switch (context.documentList[index].name) {
                                case 'Quality Handbooks':
                                    context.documentList[index].order = 1;
                                    context.documentList[index].image = 23;
                                break;
                                case 'Test Results Certificates':
                                    context.documentList[index].order = 2;
                                    context.documentList[index].image = 33;
                                break;
                                case 'Production Orders':
                                    context.documentList[index].order = 3;
                                    context.documentList[index].image = 8;
                                break;
                                case 'Production Fittings':
                                    context.documentList[index].order = 4;
                                    context.documentList[index].image = 26;
                                break;
                                case 'Production Tech Packs':
                                    context.documentList[index].order = 5;
                                    context.documentList[index].image = 27;
                                break;
                                case 'Certificates':
                                    context.documentList[index].order = 6;
                                    context.documentList[index].image = 21;
                                break;
                                case 'Production Status':
                                    context.documentList[index].order = 7;
                                    context.documentList[index].image = 14;
                                break;
                                case 'Administration':
                                    context.documentList[index].order = 8;
                                    context.documentList[index].image = 25;
                                break;
                                case 'Style Sheets':
                                    context.documentList[index].order = 9;
                                    context.documentList[index].image = 28;
                                break;
                                case 'Price Lists':
                                    context.documentList[index].order = 10;
                                    context.documentList[index].image = 7;
                                break;
                                case 'Pictures':
                                    context.documentList[index].order = 11;
                                    context.documentList[index].image = 5;
                                break;
                                case 'Samples Fittings':
                                    context.documentList[index].order = 12;
                                    context.documentList[index].image = 26;
                                break;
                                case 'Project Sheets PDF':
                                    context.documentList[index].order = 13;
                                    context.documentList[index].image = 31;
                                break;
                                case 'Style Sheets PDF':
                                    context.documentList[index].order = 14;
                                    context.documentList[index].image = 32;
                                break;
                                case 'Fabrics & Trims':
                                    context.documentList[index].order = 15;
                                    context.documentList[index].image = 2;
                                break;
                                case 'Invoices':
                                    context.documentList[index].order = 16;
                                    context.documentList[index].image = 3;
                                break;
                                case 'Delivery Notes':
                                    context.documentList[index].order = 17;
                                    context.documentList[index].image = 1;
                                break;
                                case 'Agreements':
                                    context.documentList[index].order = 18;
                                    context.documentList[index].image = 30;
                                break;
                               
                            
                                default:
                                    break;
                            }
                            
                        }


                        context.documentListCopy = context.documentList;
                    }
                    context.documentsListLoading = false;
                    this.changeDetector.markForCheck();
                }),
                catchError((res) => {
                    context.documentsListLoading = false;
                    return of();
                }),
                finalize(() => {
                    context.documentsListLoading = false;
                })
            )
            .subscribe();
    }

    public getOfficeCategoriesList() {
        var context = this;
        context.categoriesView = true;
        context.documentsListLoading = true;
        context.documentCategoryService.getDocumentCategoriesFullList(context.token, "")
            .pipe(
                tap((res) => {
                    if (res.data != null) {
                        var officesCategoryId = null;
                        res.data.forEach(element => {
                            if (element.internalCode == 'OFFICES') {
                                officesCategoryId = element.id;
                            }
                        });

                        if (officesCategoryId != null) {
                            context.documentList = res.data.filter(function (element) {
                                if (element.fatherId == officesCategoryId) {
                                    return element;
                                }

                            });
                        } else {
                            context.documentList = [];
                        }


                        for (let index = 0; index < context.documentList.length; index++) {
                           
                            switch (context.documentList[index].name) {
                                case 'Invoices':
                                    context.documentList[index].order = 2;
                                    context.documentList[index].image = 3;
                                break;
                                case 'Delivery Notes':
                                    context.documentList[index].order = 1;
                                    context.documentList[index].image = 1;
                                break;
                                case 'Agreements':
                                    context.documentList[index].order = 3;
                                    context.documentList[index].image = 30;
                                break;
                               
                            
                                default:
                                    break;
                            }
                            
                        }

                        context.documentList.sort((a, b) => a.order-b.order);



                        context.documentListCopy = context.documentList;


                    }

                    context.categoriesView = true;
                    context.officesView = true;
                    context.documentsListLoading = false;
                    this.changeDetector.markForCheck();
                }),
                catchError((res) => {
                    context.documentsListLoading = false;
                    return of();
                }),
                finalize(() => {
                    context.documentsListLoading = false;
                })
            )
            .subscribe();
    }

    public getDocumentsByCategory(nameCategory: string, event: LazyLoadEvent =null)
    {
        var context = this;
        context.documentsListLoading = true;
        context.categoriesView = false;
        context.documentList = [];
        context.documentListCopy = [];

        context.documentCategoryService.getDocumentCategoriesFullList(context.token, "")
            .pipe(
                tap((res) => {
                    if (res.data != null) {
                        var categoriesList = [nameCategory];
                        context.documentListCopy = res.data.filter(function (element) {
                            if (categoriesList.includes(element.name)) {
                                return element;
                            }
                        });
                        context.documentService.getDocumentList(context.token, context.event_lazy_local_table_products, null, context.currentInstaceId, context.documentListCopy[0].id)
                            .pipe(
                                tap((res) => {
                                    if (res.data != null) {
                                        var responseData = res.data;
                                        context.totalRecords = res.data.length;
                                        context.documentList = [];
                                        responseData.forEach(async documentData => {
                                            context.documentList.push({
                                                id: documentData.id,
                                                originalFileName: documentData.originalFileName,
                                                fileName: documentData.fileName,
                                                blobFilePath: documentData.blobFilePath,
                                                mimeType: documentData.mimeType,
                                                notes: documentData.notes,
                                                description: documentData.description,
                                                status: (documentData.status != null ? context.documentsStatus[documentData.status].label : ''),
                                                fileContent: documentData.fileContent,
                                                creationTime: documentData.creationTime,
                                                serviceInstanceId: documentData.serviceInstanceId
                                            });
                                            context.documentListCopy = context.documentList;
                                            this.changeDetector.markForCheck();
                                        });
                                    }
                                    context.documentsListLoading = false;
                                    this.changeDetector.markForCheck();
                                }), catchError((res) => {
                                    context.documentsListLoading = false;
                                    this.changeDetector.markForCheck();
                                    if (res.error?.message != "'null' is not a valid 24 digit hex string.") {
                                        context.messageService.add({
                                            severity: 'error',
                                            summary: 'Error',
                                            detail: res.error?.message ?? res.error.title
                                        })
                                    }
                                    return of();
                                }), finalize(() => { context.documentsListLoading = false; this.changeDetector.markForCheck(); })).subscribe();
                    }
                }),
                catchError((res) => {
                    context.documentsListLoading = false;
                    return of();
                }),
                finalize(() => {
                    context.documentsListLoading = false;
                })
            )
            .subscribe();
    }

    public getDocumentsList(internalCode: string, categoryId: string = null, event: LazyLoadEvent = null) {
        var context = this;
        context.documentsListLoading = true;
        context.categoriesView = false;
        context.documentList = [];
        context.documentListCopy = [];
        if (event != null) {
            context.event_lazy_local_table_products = event;
        }
        context.documentListLoading = true;
        context.documentService.getDocumentList(context.token, context.event_lazy_local_table_products, internalCode, context.currentInstaceId, categoryId).pipe(
            tap((res) => {
                if (res.data != null) {
                    var responseData = res.data;
                    context.totalRecords = res.data.length;
                    responseData.forEach(async documentData => {
                        context.documentList.push({
                            id: documentData.id,
                            originalFileName: documentData.originalFileName,
                            fileName: documentData.fileName,
                            blobFilePath: documentData.blobFilePath,
                            mimeType: documentData.mimeType,
                            notes: documentData.notes,
                            description: documentData.description,
                            status: (documentData.status != null ? context.documentsStatus[documentData.status].label : ''),
                            fileContent: documentData.fileContent,
                            creationTime: documentData.creationTime,
                            serviceInstanceId: documentData.serviceInstanceId
                        });
                        context.documentListCopy = context.documentList;
                        this.changeDetector.markForCheck();
                    });
                }
                context.documentsListLoading = false;
                this.changeDetector.markForCheck();
            }), catchError((res) => {
                context.documentsListLoading = false;
                this.changeDetector.markForCheck();
                if(res.error?.message != "'null' is not a valid 24 digit hex string."){
                    context.messageService.add({
                        severity: 'error',
                        summary: 'Error',
                        detail: res.error?.message ?? res.error.title
                    })
                }
                return of();
            }), finalize(() => { context.documentsListLoading = false; this.changeDetector.markForCheck(); })).subscribe();
    }

    public exportCollectionPlan(typeExport: ExportFileType) {
        let context = this;
        context.collectionPlanExportLoading = true;

        context.collectionPlanService.exportCollectionPlanSimple(context.token, null, context.collectionPlanDetailId, typeExport).pipe(
            tap((response) => {
                if (typeof response.data !== 'undefined' && typeof response.data.content !== 'undefined' && response.data.content !== '') {
                    context.exportCollectionPlanSimpleFile(response.data.content, context.collectionPlanData.header.name, typeExport, context.collectionPlanData.versionNumber);
                    context.messageService.add({
                        severity: "success",
                        summary: "Success",
                        detail: response.message ?? "Exported the file successfully"
                    });
                } else {
                    context.messageService.add({
                        severity: "error",
                        summary: "Error",
                        detail: "Could not export the file",
                    });
                    return of();
                }
                context.changeDetector.markForCheck();
            }), catchError((error) => {
                context.messageService.add({
                    severity: "error",
                    summary: "Error",
                    detail: error.message ?? error.error.title
                });
                return of();
            }), finalize(() => {
                context.collectionPlanExportLoading = false;
                context.changeDetector.markForCheck();
            })).subscribe();
    }
}
