<div class="layout-menu-wrapper" [ngClass]="{'layout-sidebar-active': appMain.sidebarActive}" *ngIf="isSidebarActive"
     (click)="appMain.onSidebarClick($event)" (mouseover)="appMain.onSidebarMouseOver($event)" (mouseleave)="appMain.onSidebarMouseLeave($event)">
    <div class="menu-logo" *ngIf="!appMain.isHorizontal() || appMain.isMobile()">

        <a href="#/home" class="app-name" *ngIf="!appMain.isSlim() || appMain.isMobile()" style="text-align: center;">
            
            <h1 _ngcontent-dyf-c109="" style="font-weight: 100; color: #fff;"><b _ngcontent-dyf-c109="">Philo</b>App</h1>
           <!--<h4 style="color:#fff;margin-top:10px;margin-bottom: 0px;display: inline; position: relative;top: -10px;"><b>Philo</b><span style="font-weight: 100;">Customer</span></h4>-->
        </a> 
    </div>
   

    
</div>
<div class="center-logo">
    <img src="assets/images/logo-alkenium.png">
</div>
