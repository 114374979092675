<div class="p-col-12">
    <div class="card" *ngIf="collectionPlanData !== undefined">
        <h3 style="color: #222;">Collection Details</h3>
        <div style="background-color: #ac965f;color:#fff;border-radius: 5px;">
            <div class="p-formgroup-inline" >
                <div class="p-col-12 p-sm-12 p-md-6 p-lg-4"><b>Name:</b> {{collectionPlanData.header.name}}</div>
                <div class="p-col-12 p-sm-12 p-md-6 p-lg-4"><b>Transaction Id:</b> {{collectionPlanData.header.transactionId}}</div>
                <div class="p-col-12 p-sm-12 p-md-6 p-lg-4"><b>Project:</b> {{collectionPlanData.header.project}}</div>
                <div class="p-col-12 p-sm-12 p-md-6 p-lg-4"><b>Season:</b> {{collectionPlanData.header.season}}</div>
                <div class="p-col-12 p-sm-12 p-md-6 p-lg-4"><b>Collection:</b> {{collectionPlanData.header.collection}}</div>
            </div>
            <div class="p-formgroup-inline" style="border-top:1px solid #948150;">
                <div class="p-col-12"><b>User:</b> {{collectionPlanData.header.user}}</div>
            </div>
        </div>
    </div>
    <div class="card">
        <p-table #dtCollectionPlan [value]="collectionPlanItemsList" dataKey="id" styleClass="p-datatable-oris" [rowHover]="true"
            [globalFilterFields]="['family', 'productCategory', 'line', 'management']" (onLazyLoad)="getItemsList($event)"
            [lazy]="true" [totalRecords]="(totalItemsRecords)" [rows]="(itemsPerPage)" [paginator]="true" [filterDelay]="0"
            [loading]="collectionPlanItemsListLoading">
            <ng-template pTemplate="caption">
                <div class="p-d-flex p-flex-column p-flex-md-row p-jc-md-between table-header">
                    Collection items list
                </div>
            </ng-template>
            <ng-template pTemplate="header">
                <tr>
                    <th pSortableColumn="header.family">
                        <div class="p-d-flex p-jc-between p-ai-center">
                            Family
                            <p-sortIcon field="header.family"></p-sortIcon>
                        </div>
                    </th>
                    <th pSortableColumn="header.productCategory">
                        <div class="p-d-flex p-jc-between p-ai-center">
                            Product Category
                            <p-sortIcon field="header.productCategory"></p-sortIcon>
                        </div>
                    </th>
                    <th pSortableColumn="header.line">
                        <div class="p-d-flex p-jc-between p-ai-center">
                            Line
                            <p-sortIcon field="header.line"></p-sortIcon>
                        </div>
                    </th>
                    <th pSortableColumn="header.management">
                        <div class="p-d-flex p-jc-between p-ai-center">
                            Management
                            <p-sortIcon field="header.management"></p-sortIcon>
                        </div>
                    </th>
                   
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-collectionPlan let-expanded="expanded">
                <tr class="p-selectable-row">
                    <td>
                        {{collectionPlan.family}}
                    </td>
                    <td>
                        {{collectionPlan.productCategory}}
                    </td>
                    <td>
                        {{collectionPlan.line}}
                    </td>
                    <td>
                        {{collectionPlan.management}}
                    </td>
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td>No records found</td>
                </tr>
            </ng-template>
        </p-table>
    </div>
    <div class="card">
        <p-table #dtCollectionPlan [value]="collectionPlanList" dataKey="id" styleClass="p-datatable-oris" [rowHover]="true"
            [globalFilterFields]="['collection', 'lpCode', 'project', 'season', 'productCategory', 'line']" (onLazyLoad)="getList($event)"
            [lazy]="true" [totalRecords]="(totalRecords)" [rows]="(itemsPerPage)" [paginator]="true" [filterDelay]="0"
            [loading]="collectionPlanListLoading">
            <ng-template pTemplate="caption">
                <div class="p-d-flex p-flex-column p-flex-md-row p-jc-md-between table-header">
                    Collection details list
                    
                </div>
            </ng-template>
            <ng-template pTemplate="header">
                <tr>
                    <th pSortableColumn="header.collection">
                        <div class="p-d-flex p-jc-between p-ai-center">
                            Collection
                            <p-sortIcon field="header.collection"></p-sortIcon>
                        </div>
                    </th>
                    <th pSortableColumn="header.lpCode">
                        <div class="p-d-flex p-jc-between p-ai-center">
                            LP Code
                            <p-sortIcon field="header.lpCode"></p-sortIcon>
                        </div>
                    </th>
                    <th pSortableColumn="header.project">
                        <div class="p-d-flex p-jc-between p-ai-center">
                            Project
                            <p-sortIcon field="header.project"></p-sortIcon>
                        </div>
                    </th>
                    <th pSortableColumn="header.season">
                        <div class="p-d-flex p-jc-between p-ai-center">
                            Season
                            <p-sortIcon field="header.season"></p-sortIcon>
                        </div>
                    </th>
                    <th pSortableColumn="header.productCategory">
                        <div class="p-d-flex p-jc-between p-ai-center">
                            Category
                            <p-sortIcon field="header.productCategory"></p-sortIcon>
                        </div>
                    </th>
                    <th pSortableColumn="header.line">
                        <div class="p-d-flex p-jc-between p-ai-center">
                            Line
                            <p-sortIcon field="header.line"></p-sortIcon>
                        </div>
                    </th>
                   
                </tr>
            </ng-template>
            <ng-template pTemplate="body" let-collectionPlan let-expanded="expanded">
                <tr class="p-selectable-row">
                    <td>
                        {{collectionPlan.collection}}
                    </td>
                    <td>
                        {{collectionPlan.lpCode}}
                    </td>
                    <td>
                        {{collectionPlan.project}}
                    </td>
                    <td>
                        {{collectionPlan.season}}
                    </td>
                    <td>
                        {{collectionPlan.productCategory}}
                    </td>
                    <td>
                        {{collectionPlan.line}}
                    </td>
                   
                </tr>
            </ng-template>
            <ng-template pTemplate="emptymessage">
                <tr>
                    <td>No records found</td>
                </tr>
            </ng-template>
        </p-table>
    </div>
</div>