import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { LazyLoadEvent } from 'primeng/api';
import { Observable } from 'rxjs';
import { BaseRequestMethod } from 'src/app/core/base/base.service'; 
import { BaseService } from 'src/app/core/base/base.service';

@Injectable()
export class DocumentCategoryService {
    constructor(private http: HttpClient, private baseservice: BaseService) {}

    getDocumentCategoriesList(token:string, event:LazyLoadEvent, serviceInstanceId: string):Observable<any>{
        return this.baseservice.callService<any>(BaseRequestMethod.POST, 'documents/categories', token, {
            filtersDocumentCategory: {
                serviceInstanceId : serviceInstanceId ?? null
            },
            first:event?.first ?? null,
            globalFilter:event?.globalFilter ?? null,
            rows:event?.rows ?? null,
            end:null,
            sortField:event?.sortField ?? null,
            sortOrder:event?.sortOrder ?? null
        });
    }

    getDocumentCategoriesFullList(token:string, instanceId:string):Observable<any>{
        return this.baseservice.callService<any>(BaseRequestMethod.POST, 'documentscategories/categories-list', token, {
            instanceId:instanceId
        });
    }

    getDocumentCategory(token:string, categoryId:string):Observable<any>{
        return this.baseservice.callService<any>(BaseRequestMethod.GET, 'documents/categories/' + categoryId, token, {});
    }

    createDocumentCategory(token:string, category:any):Observable<any>{
        return this.baseservice.callService<any>(BaseRequestMethod.POST, 'documents/categories/create', token,{
            name: category.name,
            description: category.description,
            fatherId: category.father,
            serviceInstanceId: category.serviceInstanceId
        });
    }

    updateDocumentCategory(token:string, category:any):Observable<any>{
        return this.baseservice.callService<any>(BaseRequestMethod.PUT, 'documents/categories/update', token,{
            id: category.id,
            name: category.name,
            description: category.description,
            fatherId: category.father,
            serviceInstanceId: category.serviceInstanceId
        });
    }

    deleteDocumentCategory(token:string, id:string):Observable<any>{
        return this.baseservice.callService<any>(BaseRequestMethod.DELETE, 'documents/categories/' + id, token, {});
    }

}