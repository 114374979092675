import { HttpClient } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { LazyLoadEvent } from "primeng/api";
import { Observable } from "rxjs";
import { BaseRequestMethod } from "src/app/core/base/base.service";
import { BaseService } from "src/app/core/base/base.service";

@Injectable()
export class CollectionPlanDetailsService {
    
    constructor(private http: HttpClient, private baseservice: BaseService) {}

    getCollectionPlanDetailsList(token:string, event:LazyLoadEvent, serviceInstanceId:string, collectionplanId:string):Observable<any>{
        return this.baseservice.callService<any>(BaseRequestMethod.POST, "collectionplans/collectionplan/details/" + collectionplanId, token, {
            first:event?.first ?? null,
            globalFilter:event?.globalFilter ?? null,
            rows:event?.rows ?? null,
            end:null,
            sortField:event?.sortField ?? null,
            sortOrder:event?.sortOrder ?? null});
    }

    getCollectionPlanItemsList(token:string, event:LazyLoadEvent, serviceInstanceId:string, collectionplanId:string):Observable<any>{
        return this.baseservice.callService<any>(BaseRequestMethod.POST, "collectionplans/collectionplan/items/" + collectionplanId, token, {
            first:event?.first ?? null,
            globalFilter:event?.globalFilter ?? null,
            rows:event?.rows ?? null,
            end:null,
            sortField:event?.sortField ?? null,
            sortOrder:event?.sortOrder ?? null});
    }

    getCollectionPlan(token:string, event:LazyLoadEvent, serviceInstanceId:string, collectionId: string):Observable<any>{
        return this.baseservice.callService<any>(BaseRequestMethod.GET, "collectionplans/collectionplan/"+collectionId, token, null);
    }
}