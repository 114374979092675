import { HttpClient } from "@angular/common/http";
import { Permission, PermissionJSON, ChildrenPermissionJSON } from "../modules/permissions/domain/permission";
import * as permissionData from 'src/assets/json/permission-labels.json'
export class TreenNodes{

    public jsonModel: PermissionJSON [];    
    public data;
     
    constructor(){
        this.data = permissionData;
    }

    transformData(permissionList:Permission[])
    {
        this.jsonModel = [];
        permissionList.forEach(permission => {

            let model = {
                "label": this.data.default["modules"][permission.module],
                "data": permission.module,
                "expandedIcon": 'pi pi-folder-open',
                "collapsedIcon": 'pi pi-folder',
                "leaf": false,
                "children": [] as ChildrenPermissionJSON
            } as PermissionJSON;

            if(permission.action != null && permission.action.length > 0)
            {
                permission.action.forEach(action => {
                    let children = {
                        "label": action,
                        "icon": '',
                        "data": action,
                        "leaf":true
                    };

                    model.children.push(children);
                });
            }

            this.jsonModel.push(model);
        });
        
        let json = JSON.stringify(this.jsonModel);

        return json;
    }

}