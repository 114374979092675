import { Injectable } from "@angular/core";
import { Router, ActivatedRoute } from "@angular/router";
import { of, pipe } from "rxjs";
import { catchError, finalize, retry, tap } from "rxjs/operators";
import { AppGlobals } from "src/app/app.globals";

@Injectable()
export class AuthenticationUtils {
    private _router: Router;
    private _activatedRoute: ActivatedRoute;
    private _configurations: any;

    constructor(
        private router: Router,
        private activatedRoute: ActivatedRoute
      ) {
        this._router = router;
        this._activatedRoute = activatedRoute;
        this._configurations = AppGlobals.getAppConfigurations();
      }

    public manageAuthorized(redirectToHome: boolean = false): void{
        let token = sessionStorage.getItem("alkenium_Token");
        console.log(token);
        if(token == null)
        {
            this.manageUnauthorized();
        }
        return;
    }

    private manageUnauthorized() {
        sessionStorage.removeItem("alkenium_Token");
        this._router.navigate(["/login"]);
    }
}