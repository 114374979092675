import { Component, OnInit, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { ConfirmationService, LazyLoadEvent, MessageService } from "primeng/api";
import { Dropdown } from "primeng/dropdown";
import { of } from "rxjs";
import { catchError, finalize, map, tap } from "rxjs/operators";
import { AppGlobals } from "src/app/app.globals";
import { AppBreadcrumbService } from "src/app/core/breadcumb/app.breadcrumb.service";
import { CustomerService } from "../customers/service/customerservice";
import { ServiceInstance } from "../instances/domain/instance";
import { InstanceService } from "../instances/service/instanceservice";
import { CollectionPlan } from "./domain/collectionPlan";
import { CollectionPlanDetail } from "./domain/collectionPlanDetail";
import { CollectionPlanHeaderItem } from "./domain/collectionPlanHeaderItem";
import { CollectionPlanDetailsService } from "./service/collectionPlanDetails.service";


@Component({
    templateUrl: "./collectionPlanDetails.component.html",
    styleUrls: ["./collectionPlanDetails.component.scss"],
})


export class collectionPlanDetailsComponent {
    private token: string;
    public collectionPlanList: CollectionPlanDetail[];
    public collectionPlanItemsList: CollectionPlanHeaderItem[];
    public clientsList: Document[] = [];
    public serviceInstanceList: ServiceInstance[];
    public serviceInstancesFilter: ServiceInstance[];
    public collectionPlanListLoading: boolean = true;
    public collectionPlanItemsListLoading: boolean = true;
    public warningModal: boolean;
    public totalRecords: number;
    public totalItemsRecords: number;
    public collectionPlanDetailId: string;
    public collectionPlanData;
    event_lazy_local: LazyLoadEvent;
    itemsPerPage: number = 5;

    @ViewChild("dropdown_serviceIstances")
    dropdown_serviceIstances: Dropdown;

    constructor(
        private breadcrumbService: AppBreadcrumbService,
        private messageService: MessageService,
        private serviceInstaceService: InstanceService,
        private confirmationService: ConfirmationService,
        private route: ActivatedRoute,
        private collectionPlanDetailsService: CollectionPlanDetailsService,
        private router:Router,
        private customerService: CustomerService) {
        let context = this;
        context.token = sessionStorage.getItem('alkenium_Token').toString();
        context.breadcrumbService.setItems([AppGlobals.setBreadCrumb('Collection Plans', '/collectionplans', AppGlobals.userHasPermission("IS_CLIENT"))]);
        context.event_lazy_local = {} as LazyLoadEvent;
        this.collectionPlanDetailId = this.route.snapshot.paramMap.get('id');
        this.getCollectionPlanData(this.collectionPlanDetailId);
    }

    

    setServiceIstanceFilter()
    {
        this.serviceInstaceService.getServiceInstanceList(this.token, null, null, null)
            .pipe(
                tap((res) => {
                    if(res.data != null)
                    {
                        this.serviceInstancesFilter = res.data.map((service) => {
                            return {
                            id: service.id,
                            name: service.name
                        }});
                    }
                }),
                catchError(() => {
                    return of();
                })
            )
            .subscribe();
    }

    private setClientsDropdown(){
        let context = this;
        context.customerService.getCustomerList(context.token, null, null) .pipe(
            tap((response) => {
                if(response.data != null){
                    context.clientsList = response.data.map((clients) => {
                        return {
                            id: clients.id,
                            name: clients.companyName
                        }
                });
            }
        }),catchError((error) => {return of();})).subscribe();
    }

    public userHasPermission(permission: string){
        return (permission != '' && permission != null ? (!AppGlobals.userHasPermission(permission) ? false : true) : true);
    }

    private setServiceInstancesDropdown()
    {
        this.serviceInstaceService.getServiceInstanceList(this.token, null, null, null)
            .pipe(
                tap((res) => {
                    if(res.data != null)
                    {
                        this.serviceInstanceList = res.data.map((service) => {
                            return {
                                id: service.id,
                                name: service.name
                            }
                        });
                    }
                }),
                catchError((res) => {
                    return of();
                })
            ).subscribe();
    }

    public getItemsList(event:LazyLoadEvent) {
        let context = this;
        if(event != null){
            context.event_lazy_local = event;
        }

        let serviceInstanceId = this.dropdown_serviceIstances?.value ?? null;

        context.collectionPlanItemsListLoading = true;
        context.collectionPlanDetailsService.getCollectionPlanItemsList(context.token, context.event_lazy_local, serviceInstanceId, this.collectionPlanDetailId)
            .pipe(
                tap((res) => {
                    if(res.data != null){
                        let slice = res.data.slice(context.event_lazy_local.first, (context.event_lazy_local.first + context.event_lazy_local.rows));
                        context.totalItemsRecords = res.data.length;

                        context.collectionPlanItemsList = slice.map((collectionPlan) => {
                            return {
                                family: collectionPlan.family,
                                productCategory: collectionPlan.productCategory,
                                line: collectionPlan.line,
                                management: collectionPlan.management,
                                
                            }
                        });
                        context.collectionPlanItemsListLoading = false;
                    }
                }),
                catchError((res) => {
                    context.messageService.add({
                        severity:'error',
                        summary:'Error',
                        detail: res.error?.message ?? res.error.title
                    })
                    return of();
                }),
                finalize(() => {

                })
            )
            .subscribe();
    }

    public getList(event:LazyLoadEvent) {
        let context = this;
        if(event != null){
            context.event_lazy_local = event;
        }

        let serviceInstanceId = this.dropdown_serviceIstances?.value ?? null;

        context.collectionPlanListLoading = true;
        context.collectionPlanDetailsService.getCollectionPlanDetailsList(context.token, context.event_lazy_local, serviceInstanceId, this.collectionPlanDetailId)
            .pipe(
                tap((res) => {
                    if(res.data != null){
                        let slice = res.data.slice(context.event_lazy_local.first, (context.event_lazy_local.first + context.event_lazy_local.rows));
                        context.totalRecords = res.data.length;

                        context.collectionPlanList = slice.map((collectionPlan) => {
                            return {
                                season: collectionPlan.season,
                                productCategory: collectionPlan.productCategory,
                                project: collectionPlan.project,
                                collection: collectionPlan.collection,
                                line: collectionPlan.line,
                                lpCode: collectionPlan.lpCode,
                                
                            }
                        });
                        context.collectionPlanListLoading = false;
                    }
                }),
                catchError((res) => {
                    context.messageService.add({
                        severity:'error',
                        summary:'Error',
                        detail: res.error?.message ?? res.error.title
                    })
                    return of();
                }),
                finalize(() => {

                })
            )
            .subscribe();
    }

    getCollectionPlanData(collectionId:string)
    {
        this.collectionPlanDetailsService.getCollectionPlan(this.token, null, null, collectionId)
            .pipe(
                tap((res) => {
                    if(res.data != null)
                    {
                        this.collectionPlanData = res.data;
                    }
                }),
                catchError(() => {
                    return of();
                })
            )
            .subscribe();
    }
}