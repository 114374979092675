import {RouterModule} from '@angular/router';
import {NgModule} from '@angular/core';
import {AppMainComponent} from './app.main.component';
import {AppNotfoundComponent} from './pages/app.notfound.component';
import {AppErrorComponent} from './pages/app.error.component';
import {AppAccessdeniedComponent} from './pages/app.accessdenied.component';
import { DashboardComponent } from './modules/dashboard/dashboard.component';
import { InstanceComponent } from './modules/instances/instance.component';
import { CustomerComponent } from './modules/customers/customer.component';
import { UserComponent } from './modules/users/user.component';
import { AppLoginComponent } from './modules/users/login/app.login.component';
import { AuthenticationGuard } from './modules/authentication/guards/auth-guard';
import { SupplierComponent } from './modules/suppliers/supplier.component';
import { DocumentComponent } from './modules/documents/document.component';
import { ProjectComponent } from './modules/projects/project.component';
import { GroupComponent } from './modules/groups/group.component';
import { DocumentCategoryComponent } from './modules/documents/documentCategory.component';
import { JobOrderComponent } from './modules/joborders/joborder.component';
import { OrderComponent } from './modules/orders/order.component';

@NgModule({
    imports: [
        RouterModule.forRoot([
            {
                path: '', 
                component: AppMainComponent,
                canActivate: [AuthenticationGuard],
                children: [
                    {
                        path: 'home', 
                        component: DashboardComponent,
                        canActivate: [AuthenticationGuard]
                    },
                    {
                        path: 'instances',
                        component: InstanceComponent,
                        canActivate: [AuthenticationGuard]
                    },
                    {
                        path: 'documents',
                        component: DocumentComponent,
                        canActivate: [AuthenticationGuard]
                    },
                    {
                        path: 'documents/categories',
                        component: DocumentCategoryComponent,
                        canActivate: [AuthenticationGuard]
                    },
                    {
                        path: 'projects',
                        component: ProjectComponent,
                        canActivate: [AuthenticationGuard]
                    },
                    {
                        path: 'suppliers',
                        component: SupplierComponent,
                        canActivate: [AuthenticationGuard]
                    },
                    {
                        path:'customers',
                        component: CustomerComponent,
                        canActivate: [AuthenticationGuard]
                    },
                    {
                        path:'users',
                        component: UserComponent,
                        canActivate: [AuthenticationGuard]
                    },
                    {
                        path:'users/groups',
                        component: GroupComponent,
                        canActivate: [AuthenticationGuard]
                    },
                    {
                        path:'job-orders/:id',
                        component: JobOrderComponent,
                        canActivate: [AuthenticationGuard]
                    },
                    {
                        path:'job-orders/:id/orders/:joborder',
                        component: OrderComponent,
                        canActivate: [AuthenticationGuard]
                    },
                    {
                        path: 'documents/:id',
                        component: DocumentComponent,
                        canActivate: [AuthenticationGuard]
                    },
                ]
            },
            {
                path:"notfound",
                component: AppNotfoundComponent
            },
            {
                path:"login",
                component: AppLoginComponent
            },
            {path: '**', redirectTo: '/notfound'},
        ], {scrollPositionRestoration: 'enabled'})
    ],
    exports: [RouterModule]
})
export class AppRoutingModule {
}
