<div class="layout-dashboard">
    <div *ngIf="collectionPlanExportLoading">
        <div class="p-fl-container"></div>
        <div class="p-d-flex p-fl-spinner">
            <p-progressSpinner styleClass="custom-spinner"></p-progressSpinner>
        </div>
    </div>
    <div class="p-grid" style="display:none;">
        <div class="p-col-12 p-md-12">
            <h2 class="dashboard-title"><span>Factory</span> navigation</h2>
        </div>
    </div>
    <div class="p-grid" style="display: none;">
        <div class="p-col-12 p-md-12">
            <div id="mapplic"></div>
        </div>
    </div>

    <div style="width:100%;position:relative;">
        <ul class="home-area-gallery">
            <li class="f0 outer-element" (click)="openSection('collections', false)">
                <div>
                    <div class="description r-b"><span>Fabrics & trims</span></div>
                </div>
            </li>
            <li class="f1 outer-element" (click)="openSection('collections', false)">
                <div>
                    <div class="description"><span>Collection plans</span></div>
                </div>
            </li>
            <li  (click)="openSection('certificates', false)" style="position: absolute; left: 10vw; top: 2.9vw;">
                <div style="position:relative" class="inner-element">
                    <div class="f2 inner-element"></div>
                    <div class="description r-g"><span>Certificates</span></div>
                </div>
            </li>
            <li (click)="openSection('qualityHandbook', false)" style="position: absolute;left: 36vw;top: 9.9vw;">
                <div style="position:relative" class="inner-element">
                    <div class="f3 inner-element"></div>
                    <div class="description l-g"><span>Quality handbooks</span></div>
                </div>
            </li>
            <li class="f4 outer-element" (click)="openSection('projects', false)">
                <div>
                    <div class="description"><span>Projects</span></div>
                </div>
            </li>
            <li (click)="openSection('production', false)" style="position: absolute; left: 23vw; top: 2.9vw;
            width: 13vw;
            height: 26vw;">
                <div style="position:relative" class="inner-element" style="width: 13vw;height: 26vw;">
                    <div class="f5 inner-element" style="width: 13vw;height: 26vw;"></div>
                    <div class="description l-g"><span>Production</span></div>
                </div>
            </li>
            <li class="f6 outer-element" (click)="openSection('samples', false)">
                <div>
                    <div class="description"><span>Samples</span></div>
                </div>
            </li>
            <li class="f7 outer-element" (click)="openSection('sampleFitting', false)">
                <div>
                    <div class="description"><span>Fitting</span></div>
                </div>
            </li>
            <li class="f8 outer-element" (click)="openSection('documents', false)">
                <div>
                    <div class="description l-w"><span><div class="word-beark">All</div>Documents</span></div>
                </div>
            </li>
            <li class="f9 outer-element"  (click)="openSection('offices', false)">
                <div>
                    <div class="description l-b"><span>Administration</span></div>
                </div>
            </li>
        </ul>

    </div>
    <p-dialog header="&nbsp;" [(visible)]="categoriesListModal" id="categoriesListModalArea" class="category-element {{modalColorClass}}" [modal]="true" [style]="{width: '1216px'}"
        styleClass="p-fluid" [baseZIndex]="10000" [draggable]="false" [resizable]="false" >
        <div class="left-top-corner"></div>
        <div class="left-modal-image">
            <img src="../../../assets/images/categories/{{modalImage}}"/>
        </div>
        <div class="modal-body-area">
            <div class="internal-modal-header">
                <h2 style="text-transform: capitalize;"><b>{{activeModalTitle.title}}</b> {{activeModalTitle.subtitle}}</h2>
                <small>Complete list</small>
                <div class="form-search-box"><i class="pi pi-search"></i><input type="text"
                        (input)="filterCategoriesList($event)" placeholder="Search..." /></div>
            </div>
            <div class="internal-modal-form" >
                <ul *ngIf="documentList.length > 0 && !documentsListLoading">
                    <li *ngFor="let element of documentList;index as i"  [ngClass]="{'list-element-documents' : element.blobFilePath != '' &&  element.blobFilePath != null}">
                        <div *ngIf="element.name != '' &&  element.name != null" (click)=" (element.name == 'Style Sheets' ? openSection('styleSheet') : (element.name == 'Collection Plans' ? openSection('collections') : getSelectedDocumentsList(element.internalCode, element.id)))">
                            <div class="category-element-item">
                                <div class="document-category-element-image-wrapper">
                                    <img src="../../../assets/images/categories/{{(element.name == 'Delivery Notes' ? '1' : (element.image !== undefined ? element.image : (i+ 1) ))}}.jpeg" style="width: {{(element.name == 'Delivery Notes' || element.name == 'Production Orders' ||  element.name == 'Pictures' ? '140%;' : '110%;')}}">
                                </div>
                                <div class="element-category-element-component">
                                    <h2 *ngIf="element.name != '' && element.name != null">{{element.name}}</h2>
                                    <p>{{element.description}}</p>
                                </div>
                            </div>
                        </div>
                        <div *ngIf="element.blobFilePath != '' &&  element.blobFilePath != null" style="text-align: left;cursor: default;">
                            <h4 *ngIf="element.name != '' && element.name != null">{{element.name}}</h4>
                            <h5 *ngIf="element.originalFileName != '' && element.originalFileName != null"  style="font-size: 16px;margin-bottom: 1px;display: inline-block;" >
                                <img src="../../../assets/images/pdf-icon.png" style="width:28px;"/> 
                                <span (click)="downloadDocument(element)" style="text-decoration: underline;position: relative;bottom: 10px;left: 10px;cursor:pointer; " class="download-link">
                                    {{element.originalFileName}}
                                </span>
                            </h5>
                            <p style="margin-left:20px;font-size: 16px;">{{element.description}}</p>
                            <button (click)="downloadDocument(element)" class="button-download"><span class="p-button-icon pi pi-arrow-down" style="font-family: 'primeicons'!important;" aria-hidden="true"></span> </button>
                        </div>
                       
                    </li>
                </ul>
                <div class="no-results-list" *ngIf="documentList.length == 0 && !documentsListLoading">
                    There is no data available here
                </div>
                <div class="loading-results-list" *ngIf="documentsListLoading">
                    Loading...
                </div>
            </div>
            
        </div>
        <ng-template pTemplate="footer">
            <div style="text-align:right;margin-top:15px;"  *ngIf="!categoriesView && !styleSheetView && !hideCategoryBackBtn && !officesView"> <button class="" (click)="goBackToCategories()" style="background-color: #000;
                color: #fff;
                font-size: 14px;
                font-weight: normal;
                border: 0px;
                border-radius: 0px;
                padding: 35px;
                padding-top: 5px;
                padding-bottom: 5px;">Back</button></div>
                <div style="text-align:right;margin-top:15px;"  *ngIf="!categoriesView && styleSheetView && !officesView"> <button class="" (click)="( activeModalTitle.title == 'Project Sheets PDF' || activeModalTitle.title == 'Style Sheets PDF' ? (lastOpenSection == 'documents' ? ( subcategorySectionThree ? goBackToStyleSheetCategories() : goBackToCategories()) : goBackToStyleSheetCategories()) : (lastOpenSection == 'samples' ? goBackToSamplesCategories() : (lastOpenSection == 'documents' ? goBackToCategories() : goBackToStyleCategories() )  ) )" style="background-color: #000;
                color: #fff;
                font-size: 14px;
                font-weight: normal;
                border: 0px;
                border-radius: 0px;
                padding: 35px;
                padding-top: 5px;
                padding-bottom: 5px;
            ">Back</button></div>
                <div style="text-align:right;margin-top:15px;"  *ngIf="!categoriesView && !styleSheetView && (officesView || isCollectionDetail)"> <button class="" (click)="( modalImage == '26.jpeg' ? goBackToSamplesCategories() : (modalImage == '21.jpeg' ||  modalImage == '23.jpeg'  ? goBackToProductionCategories() : (modalImage == 'modal-image.png' ? goBackToSamplesCategories() : (modalImage == '22.jpeg' ? goBackToProductionCategories() : goBackToOfficesCategories() ))))" style="background-color: #000;
                    color: #fff;
                    font-size: 14px;
                font-weight: normal;
                border: 0px;
                border-radius: 0px;
                padding: 35px;
                padding-top: 5px;
                padding-bottom: 5px;">Back</button></div>
            <button pButton pRipple type="button" (click)="closelistModal()"
                class="p-button-default close-btn-des"><img src="../../../assets/images/close-icon.png"></button>
        </ng-template>
    </p-dialog>

    <p-dialog header="&nbsp;" [(visible)]="collectionPlansListModal" [ngClass]="{'list-element-collection-plan-items' : (collectionPlanDetailVisible && !productsListVisible && !showProductDetailView) || (productsListVisible && !showProductDetailView && !collectionPlanDetailVisible) || showProductDetailView}" [modal]="true" [style]="{width: '1216px'}"
        styleClass="p-fluid" [baseZIndex]="10000" [draggable]="false" [resizable]="false" class="collection-plan-area category-element collection-plan-elements {{modalColorClass}}">
        <div class="left-top-corner"></div>
        <div class="left-modal-image">
            <img src="../../../assets/images/categories/{{modalImage}}"/>
        </div>
        <div class="modal-body-area  ">
            <div class="internal-modal-header">
                <h2 style="text-transform: capitalize;"><b>{{activeModalTitle.title}}</b> {{activeModalTitle.subtitle}}</h2>
                <small>Complete list</small>
                <div class="form-search-box" *ngIf="!collectionPlanDetailVisible && !productsListVisible && !showProductDetailView"><i class="pi pi-search"></i><input
                        type="text" (input)="filterCollectionPlansList($event)" placeholder="Search..." /></div>
            </div>
            <div class="scroll-area-body">
                <div class="collection-plan-list" *ngIf="!collectionPlanDetailVisible && !productsListVisible && !showProductDetailView">
                    <div class="internal-modal-form">
                        <ul *ngIf="collectionPlanList.length > 0 && !collectionPlansListLoading">
                            <li *ngFor="let element of collectionPlanList">
                                <h4 *ngIf="element.header.name != '' && element.header.name != null" class="collection-plan-title">{{element.header.name}}
                                </h4>
                                <p style="margin-bottom: 0px;">{{element.header.codePlaceHoder}}</p>
                                <p class="collection-plan-subtitle">{{element.header.collection}}</p>
                                <p class="collection-plan-subtitle-user">{{element.header.user}}</p>
                                <div class="buttons-area-wrapper">
                                    <button (click)="getSelectedCollectionPlanDetail(element.id)" >View collection plan</button>
                                    <button (click)="getSelectedCollectionPlanProducts(element.id, element.header.name)" style="float:right"> Products</button>
                                </div>
                            </li>
                        </ul>
                        <div class="no-results-list" *ngIf="collectionPlanList.length == 0 && !collectionPlansListLoading">
                            There is no data available here
                        </div>
                        <div class="loading-results-list" *ngIf="collectionPlansListLoading">
                            Loading...
                        </div>
                    </div>
                </div>
                <div class="collection-plan-detail" *ngIf="collectionPlanDetailVisible && !productsListVisible && !showProductDetailView">
                    <div class="internal-modal-form">
                        
                        <div style="border-radius: 0px;" *ngIf="collectionPlanData">
                            <div style="font-size: 40px;color: #8d8d8d;">
                                {{collectionPlanData.header.name}} 
                            </div>
                            <div class="internal-modal-header header-collection">
                                <div style="margin-top: 15px;"><span>Collection: {{collectionPlanData.header.collection}}</span></div>
                                <div class="form-export-box">
                                    <div *ngIf="userHasPermission('CAN_EXPORT_COLLECTIONPLAN')">
                                        <button pButton pRipple label="Export Excel" type="button" class="button-export"
                                           (click)="exportCollectionPlan(exportFileType.EXCEL)"></button>
                                        <button pButton pRipple label="Export PDF" type="button" class="button-export" 
                                            (click)="exportCollectionPlan(exportFileType.PDF)"></button>
                                    </div>
                                </div>
                            </div>
                            <div style="display:none;">
                                <div class="p-formgroup-inline">
                                    <div class="p-col-12 p-sm-12 p-md-6 p-lg-4"><b>Name:</b> {{collectionPlanData.header.name}}
                                    </div>
                                    <div class="p-col-12 p-sm-12 p-md-6 p-lg-6"><b>Transaction Id:</b>
                                        {{collectionPlanData.header.transactionId}}</div>
                                </div>
                                <div class="p-formgroup-inline" style="border-top:1px solid #272727;">
                                    <div class="p-col-12 p-sm-12 p-md-6 p-lg-4"><b>Project:</b>
                                        {{collectionPlanData.header.project}}</div>
                                    <div class="p-col-12 p-sm-12 p-md-6 p-lg-4"><b>Season:</b> {{collectionPlanData.header.season}}
                                    </div>
                                    <div class="p-col-12 p-sm-12 p-md-6 p-lg-4"><b>Collection:</b>
                                        {{collectionPlanData.header.collection}}</div>
                                </div>
                                <div class="p-formgroup-inline" style="border-top:1px solid #272727;">
                                    <div class="p-col-12"><b>User:</b> {{collectionPlanData.header.user}}</div>
                                </div>
                            </div>
                        </div>
                        <div style="margin-top: 50px;"></div>
                        <p-table #dtCollectionPlanDetails [value]="collectionPlanItemsList" *ngIf="collectionPlanData" dataKey="id" styleClass="p-datatable-oris"
                            [rowHover]="true" [globalFilterFields]="['family', 'productCategory', 'line', 'management']"
                            (onLazyLoad)="getItemsList($event)" [lazy]="true" [totalRecords]="(totalItemsRecords)"
                            [rows]="(itemsPerPageTable)" [paginator]="true" [filterDelay]="0"
                            [loading]="collectionPlanItemsListLoading">
                            <ng-template pTemplate="caption">
                                <div class="p-d-flex p-flex-column p-flex-md-row p-jc-md-between table-header">
                                    Collection items list
                                </div>
                            </ng-template>
                            <ng-template pTemplate="header">
                                <tr>
                                    <th pSortableColumn="productCategory">
                                        <div class="p-d-flex p-jc-between p-ai-center">
                                            Product
                                            <p-sortIcon field="productCategory"></p-sortIcon>
                                        </div>
                                    </th>
                                    <th pSortableColumn="nr_request">
                                        <div class="p-d-flex p-jc-between p-ai-center">
                                            Paper<br>templates
                                            <p-sortIcon field="nr_request"></p-sortIcon>
                                        </div>
                                    </th>
                                    
                                    <th pSortableColumn="nr_Sample">
                                        <div class="p-d-flex p-jc-between p-ai-center">
                                            Fabrics
                                            <p-sortIcon field="nr_Sample"></p-sortIcon>
                                        </div>
                                    </th>
                                    <th pSortableColumn="nr_Palcehoder">
                                        <div class="p-d-flex p-jc-between p-ai-center">
                                            Hanging<br>clothes
                                            <p-sortIcon field="nr_Palcehoder"></p-sortIcon>
                                        </div>
                                    </th>
                                    <th pSortableColumn="nr_Prototype">
                                        <div class="p-d-flex p-jc-between p-ai-center">
                                            Model<br>Openings
                                            <p-sortIcon field="nr_Prototype"></p-sortIcon>
                                        </div>
                                    </th>
                                    <th pSortableColumn="nr_Finisched">
                                        <div class="p-d-flex p-jc-between p-ai-center">
                                            Codes
                                            <p-sortIcon field="nr_Finisched"></p-sortIcon>
                                        </div>
                                    </th>
                                    <th pSortableColumn="">
                                        <div class="p-d-flex p-jc-between p-ai-center">
                                            Tot. codes<br>to cost.
                                            <p-sortIcon field=""></p-sortIcon>
                                        </div>
                                    </th>
                                    <th pSortableColumn="lowPrice">
                                        <div class="p-d-flex p-jc-between p-ai-center">
                                            Low<br>price
                                            <p-sortIcon field="lowPrice"></p-sortIcon>
                                        </div>
                                    </th>
                                    <th pSortableColumn="mediumPrice">
                                        <div class="p-d-flex p-jc-between p-ai-center">
                                            Medium<br>price
                                            <p-sortIcon field="mediumPrice"></p-sortIcon>
                                        </div>
                                    </th>
                                    <th pSortableColumn="hightPrice">
                                        <div class="p-d-flex p-jc-between p-ai-center">
                                            High<br>price
                                            <p-sortIcon field="hightPrice"></p-sortIcon>
                                        </div>
                                    </th>
                                    <th pSortableColumn="extraPrice">
                                        <div class="p-d-flex p-jc-between p-ai-center">
                                            Extra<br>price
                                            <p-sortIcon field="extraPrice"></p-sortIcon>
                                        </div>
                                    </th>

                                </tr>
                            </ng-template>
                            <ng-template pTemplate="body" let-collectionPlan let-expanded="expanded">
                                <tr class="p-selectable-row">
                                    <td>
                                        {{collectionPlan.productCategory}}
                                    </td>
                                    <td>
                                        {{collectionPlan.nr_request}}
                                    </td>
                                    <td>
                                        {{collectionPlan.nr_Sample}}
                                    </td>
                                    <td>
                                        {{collectionPlan.nr_Palcehoder}}
                                    </td>
                                    <td>
                                        {{collectionPlan.nr_Prototype}}
                                    </td>
                                    <td>
                                        {{collectionPlan.nr_Finisched}}
                                    </td>
                                    <td>
                                        0
                                    </td>
                                    <td>
                                        {{collectionPlan.lowPrice}} &euro;
                                    </td>
                                    <td>
                                        {{collectionPlan.mediumPrice}} &euro;
                                    </td>
                                    <td>
                                        {{collectionPlan.hightPrice}} &euro;
                                    </td>
                                    <td>
                                        {{collectionPlan.extraPrice}} &euro;
                                    </td>
                                </tr>
                            </ng-template>
                            <ng-template pTemplate="emptymessage">
                                <tr>
                                    <td>No records found</td>
                                </tr>
                            </ng-template>
                        </p-table>
                        <div style="margin-top: 50px;"></div>
                        <p-table #dtCollectionPlan [value]="collectionPlanListItemsData" *ngIf="collectionPlanData" dataKey="id" styleClass="p-datatable-oris" [rowHover]="true"
                        [globalFilterFields]="['collection', 'lpCode', 'project', 'season', 'productCategory', 'line']" (onLazyLoad)="getList($event)"
                        [lazy]="true" [totalRecords]="(totalRecords)" [rows]="(itemsPerPageTable)" [paginator]="true" [filterDelay]="0"
                        [loading]="collectionPlanListLoading">
                        <ng-template pTemplate="caption">
                            <div class="p-d-flex p-flex-column p-flex-md-row p-jc-md-between table-header">
                                Collection details list
                            </div>
                        </ng-template>
                        <ng-template pTemplate="header">
                            <tr>
                                <th pSortableColumn="header.collection">
                                    <div class="p-d-flex p-jc-between p-ai-center">
                                        Collection
                                        <p-sortIcon field="header.collection"></p-sortIcon>
                                    </div>
                                </th>
                                <th pSortableColumn="header.lpCode">
                                    <div class="p-d-flex p-jc-between p-ai-center">
                                        LP Code
                                        <p-sortIcon field="header.lpCode"></p-sortIcon>
                                    </div>
                                </th>
                                <th pSortableColumn="header.project">
                                    <div class="p-d-flex p-jc-between p-ai-center">
                                        Project
                                        <p-sortIcon field="header.project"></p-sortIcon>
                                    </div>
                                </th>
                                <th pSortableColumn="header.season">
                                    <div class="p-d-flex p-jc-between p-ai-center">
                                        Season
                                        <p-sortIcon field="header.season"></p-sortIcon>
                                    </div>
                                </th>
                                <th pSortableColumn="header.productCategory">
                                    <div class="p-d-flex p-jc-between p-ai-center">
                                        Category
                                        <p-sortIcon field="header.productCategory"></p-sortIcon>
                                    </div>
                                </th>
                                <th pSortableColumn="header.line">
                                    <div class="p-d-flex p-jc-between p-ai-center">
                                        Line
                                        <p-sortIcon field="header.line"></p-sortIcon>
                                    </div>
                                </th>
                            
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-collectionPlan let-expanded="expanded">
                            <tr class="p-selectable-row">
                                <td>
                                    {{collectionPlan.collection}}
                                </td>
                                <td>
                                    {{collectionPlan.lpCode}}
                                </td>
                                <td>
                                    {{collectionPlan.project}}
                                </td>
                                <td>
                                    {{collectionPlan.season}}
                                </td>
                                <td>
                                    {{collectionPlan.productCategory}}
                                </td>
                                <td>
                                    {{collectionPlan.line}}
                                </td>
                            
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="emptymessage">
                            <tr>
                                <td>No records found</td>
                            </tr>
                        </ng-template>
                    </p-table>
                    </div>
                </div>
                <div class="collection-plan-products"  *ngIf="productsListVisible && !showProductDetailView && !collectionPlanDetailVisible">
                    <div class="internal-modal-form">
                        <h2 style="color:#8d8d8d;margin-bottom:20px;font-size:40px;">{{collectionPlanLabel}}</h2>
                        <div style="font-size:13px;     color: #44486D;">Collection Plan Products List</div>
                        <p-table #dtCollectionPlanProduct [value]="productList" dataKey="id" styleClass="p-datatable-oris"
                        [rowHover]="true" [globalFilterFields]="['name', 'alkenium_number', 'customer', 'service_instance']"
                        (onLazyLoad)="getProductsList($event)" [lazy]="true" [totalRecords]="(totalRecords)" [rows]="(itemsPerPage)"
                        [paginator]="true" [filterDelay]="0" [loading]="productListLoading">
                        <ng-template pTemplate="caption">
                            <div class="p-d-flex p-flex-column p-flex-md-row p-jc-md-between table-header">
                                
                               
                                
                                <span class="p-input-icon-left">
                                    <i class="pi pi-search"></i>
                                    <input pInputText type="text"
                                        (input)="dtCollectionPlanProduct.filterGlobal($event.target.value, 'contains')"
                                        placeholder="Search..." />
                                </span>
                            </div>
                        </ng-template>
                        <ng-template pTemplate="header">
                            <tr>
                                <th>
                                    <div class="p-d-flex p-jc-between p-ai-center">
                                        Styleimage
                                    </div>
                                </th>
                                <th pSortableColumn="code">
                                    <div class="p-d-flex p-jc-between p-ai-center">
                                        Code
                                        <p-sortIcon field="code"></p-sortIcon>
                                    </div>
                                </th>
                                <th pSortableColumn="product_name">
                                    <div class="p-d-flex p-jc-between p-ai-center">
                                        Product Name
                                        <p-sortIcon field="product_name"></p-sortIcon>
                                    </div>
                                </th>
                                <th pSortableColumn="product_category">
                                    <div class="p-d-flex p-jc-between p-ai-center">
                                        Product Category
                                        <p-sortIcon field="product_category"></p-sortIcon>
                                    </div>
                                </th>
                                <th pSortableColumn="family">
                                    <div class="p-d-flex p-jc-between p-ai-center">
                                        Family
                                        <p-sortIcon field="family"></p-sortIcon>
                                    </div>
                                </th>
                                <th pSortableColumn="line">
                                    <div class="p-d-flex p-jc-between p-ai-center">
                                        Line
                                        <p-sortIcon field="line"></p-sortIcon>
                                    </div>
                                </th>
                                <th style="width: 8rem"></th>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="body" let-product let-expanded="expanded">
                            <tr class="p-selectable-row">
                                <td>
                                    <div *ngIf="product.styleImageUrl">
                                        <img src="{{product.styleImageUrl}}" style="width:80px"/>
                                    </div>
                                    <div *ngIf="!product.styleImageUrl">
                                        <img src="assets/images/no-image.png" style="width:80px"/>
                                    </div>
                                </td>
                                <td>
                                    {{product.code}}
                                </td>
                                <td>
                                    {{product.productName}}
                                </td>
                                <td>
                                    {{product.productCategory}}
                                </td>
                                <td>
                                    {{product.family}}
                                </td>
                                <td>
                                    {{product.line}}
                                </td>
                                <td>
                                    <button pButton type="button" *ngIf="userHasPermission('CAN_VIEW_PRODUCT_LIST')" title="Show details"
                                        icon="pi pi-info-circle" class="p-button-rounded p-button-text"
                                        (click)="showProduct(product.id)"></button>
                                    <button pButton type="button" *ngIf="userHasPermission('CAN_EXPORT_PRODUCT')" title="Export Excel"
                                        class="p-button-text" icon="pi pi-file-excel"
                                        (click)="exportProduct(product.id, product, exportFileType.EXCEL)"></button>
                                    <button pButton type="button" *ngIf="userHasPermission('CAN_EXPORT_PRODUCT')" title="Export Pdf"
                                        class="p-button-text" icon="pi pi-file-pdf" (click)="exportProduct(product.id, product, exportFileType.PDF)"></button>
                                </td>
                            </tr>
                        </ng-template>
                        <ng-template pTemplate="emptymessage">
                            <tr>
                                <td>No records found</td>
                            </tr>
                        </ng-template>
                    </p-table>
                    </div>
                </div>
                <div class="collection-plan-products" *ngIf="showProductDetailView">
                    <div class="internal-modal-form">
                    <div class="card" *ngIf="productData !== undefined">
                        <div *ngIf="loadingImages">
                            <div class="p-fl-container"></div>
                            <div class="p-d-flex p-fl-spinner">
                                <p-progressSpinner styleClass="custom-spinner"></p-progressSpinner>
                            </div>
                        </div>
                        <div style="background-color: #f3f3f3;color: #000;border-radius: 0px;border: 1px solid #ddd;">
                            <div class="p-formgroup-inline" style="border-top:1px solid #ddd;">
                                <div class="p-col-12 p-sm-12 p-md-6 p-lg-3"><b>SID:</b> {{productData.sid}}</div>
                                <div class="p-col-12 p-sm-12 p-md-6 p-lg-3"><b>Code:</b> {{productData.code}}</div>
                                <div class="p-col-12 p-sm-12 p-md-6 p-lg-3"><b>Product name:</b> {{productData.productName}}</div>
                                <div class="p-col-12 p-sm-12 p-md-6 p-lg-3"><b>Management:</b> {{productData.management}}</div>
                            </div>
                            <div class="p-formgroup-inline" style="border-top:1px solid #ddd;">
                                <div class="p-col-12 p-sm-12 p-md-6 p-lg-3"><b>Season:</b> {{productData.season}}</div>
                                <div class="p-col-12 p-sm-12 p-md-6 p-lg-3"><b>Collection:</b> {{productData.collection}}</div>
                                <div class="p-col-12 p-sm-12 p-md-6 p-lg-3"><b>Product category:</b> {{productData.productCategory}}</div>
                                <div class="p-col-12 p-sm-12 p-md-6 p-lg-3"><b>Line:</b> {{productData.line}}</div>
                            </div>
                            <div class="p-formgroup-inline" style="border-top:1px solid #ddd;">
                                <div class="p-col-12 p-sm-12 p-md-6 p-lg-6"><b>Description:</b> {{productData.description}}</div>
                                <div class="p-col-12 p-sm-12 p-md-6 p-lg-6"><b>Description Eng:</b> {{productData.descriptionEng}}</div>
                            </div>
                            <div class="p-formgroup-inline" style="border-top:1px solid #ddd;">
                                <div class="p-col-12 p-sm-12 p-md-6 p-lg-3"><b>Subject:</b> {{productData.subject}}</div>
                                <div class="p-col-12 p-sm-12 p-md-6 p-lg-3"><b>Cost:</b> &euro; {{productData.cost}}</div>
                                <div class="p-col-12 p-sm-12 p-md-6 p-lg-3"><b>UM:</b> {{productData.um}}</div>
                                <div class="p-col-12 p-sm-12 p-md-6 p-lg-3"><b>Size Range:</b> {{productData.usedSize}}</div>
                            </div>
                            <div class="p-formgroup-inline" style="border-top:1px solid #ddd;">
                                <div class="p-col-12 p-sm-12 p-md-6 p-lg-3"><b>Proto size:</b> {{productData.protoSize}}</div>
                                <div class="p-col-12 p-sm-12 p-md-6 p-lg-3"><b>Weight:</b> {{productData.weight}}</div>
                                <div class="p-col-12 p-sm-12 p-md-6 p-lg-3"><b>Intracode:</b> {{productData.intracode}}</div>
                            </div>
                        </div>
                        
                    </div>
                    <div class="card" *ngIf="productData !== undefined">
                        <div *ngIf="productData.colors.length > 0">
                            <h3 style="color: #222;">Colors</h3>
                            <div style="background-color: #f3f3f3;color: #000;border-radius: 0px;border: 1px solid #ddd;">
                                <div class="p-formgroup-inline" *ngFor="let colorItem of productData.colors" style="border-top:1px solid #ddd;">
                                    <div class="p-col-12 p-sm-12 p-md-6 p-lg-2"><b>Color code:</b><br/> {{colorItem.colorCode}}</div>
                                    <div class="p-col-12 p-sm-12 p-md-6 p-lg-4"><b>Description:</b><br/> {{colorItem.description}}</div>
                                    <div class="p-col-12 p-sm-12 p-md-6 p-lg-4"><b>Description eng:</b><br/> {{colorItem.descriptionEng}}</div>
                                    <div class="p-col-12 p-sm-12 p-md-6 p-lg-2"><b>Image color:</b><br/> <img *ngIf="colorItem.imageColor != null" [src]="sanitize(imagesUrl+colorItem.imageColor.blobFilePath)" style="width:100px"/></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card" *ngIf="productData !== undefined">
                        <div *ngIf="productData.grpComposition.length > 0">
                            <h3 style="color: #222;">GRP Composition</h3>
                            <div style="background-color: #f3f3f3;color: #000;border-radius: 0px;border: 1px solid #ddd;">
                                <div class="p-formgroup-inline" *ngFor="let grpCompositionItem of productData.grpComposition" style="border-top:1px solid #ddd;">
                                    <div class="p-col-12 p-sm-12 p-md-6 p-lg-6"><b>Group:</b><br/> {{grpCompositionItem.group}}</div>
                                    <div class="p-col-12 p-sm-12 p-md-6 p-lg-6"><b>Fibers:</b>
                                        <br/> 
                                        <div class="p-formgroup-inline" *ngFor="let grpCompositionFiberItem of grpCompositionItem.fibers" style="border-top:1px solid #ddd;">
                                            <div class="p-col-12 p-sm-12 p-md-6 p-lg-6">
                                                Fiber: {{grpCompositionFiberItem.fiber}}<br/>
                                                PFiber: {{grpCompositionFiberItem.pFiber}}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card" *ngIf="productData !== undefined">
                        <h3 style="color: #222;">Component Treatments</h3>
                        <div style="background-color: #f3f3f3;color: #000;border-radius: 0px;border: 1px solid #ddd;">
                            <div class="p-formgroup-inline">
                                <div class="p-col-12 p-sm-12 p-md-6 p-lg-3"><b>Washing:</b> {{productData.componentTreatments.washing}}</div>
                                <div class="p-col-12 p-sm-12 p-md-6 p-lg-3"><b>Bleach:</b> {{productData.componentTreatments.bleach}}</div>
                                <div class="p-col-12 p-sm-12 p-md-6 p-lg-3"><b>Tumbler dry:</b> {{productData.componentTreatments.tumbleDry}}</div>
                            </div>
                            <div class="p-formgroup-inline" style="border-top:1px solid #ddd;">
                                <div class="p-col-12 p-sm-12 p-md-6 p-lg-3"><b>Dry cleaning:</b> {{productData.componentTreatments.dryCleaning}}</div>
                                <div class="p-col-12 p-sm-12 p-md-6 p-lg-3"><b>Ironing:</b> {{productData.componentTreatments.ironing}}</div>
                            </div>
                        </div>
                    </div>
                    <div class="card" *ngIf="productData !== undefined">
                        <h3 style="color: #222;">Sketch Images</h3>
                        <div style="background-color: #f3f3f3;color: #000;border-radius: 0px;border: 1px solid #ddd;">
                            <div class="p-formgroup-inline" style="border-bottom: 1px solid #ddd;">
                                <div class="p-col-12 p-sm-12 p-md-6 p-lg-6"><b>Inspiration note:</b> {{productData.imagesShetch.noteInspiration}}</div>
                                <div class="p-col-12 p-sm-12 p-md-6 p-lg-6"><b>Inspiration treatments/embellishment note:</b> {{productData.imagesShetch.noteInspirationEmbrodeiry}}</div>
                            </div>
                            <div class="p-formgroup-inline">
                                <div class="p-col-12 p-sm-12 p-md-6 p-lg-6"><b>Note:</b> {{productData.imagesShetch.note}}</div>
                                <div class="p-col-12 p-sm-12 p-md-6 p-lg-6" ><b>Image inspiration:</b> {{productData.imagesShetch.imageInspitation}}</div>
                            </div>
                            
                        </div>
                        <div style="background-color: #fff;color: #000;border-radius: 0px;border: 1px solid #ddd;margin-top: 10px;">
                            <div class="p-formgroup-inline" style="border-top:1px solid #ddd;">
                                <div class="p-col-12 p-sm-12 p-md-6 p-lg-6" style="text-align: center;"><b>Image front</b>
                                    <br/>
                                    <img *ngIf="productData.imagesShetch.imageFront != '' && productData.imagesShetch.imageFront != null" [src]="sanitize(imagesUrl+productData.imagesShetch.imageFront.blobFilePath)" style="width:300px;margin:0 auto;">
                                </div>
                                <div class="p-col-12 p-sm-12 p-md-6 p-lg-6" style="text-align: center;"><b>Image back</b>
                                    <br/>
                                    <img *ngIf="productData.imagesShetch.imageBack != null" [src]="sanitize(imagesUrl+productData.imagesShetch.imageBack.blobFilePath)" style="width:300px;margin:0 auto;">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card" *ngIf="productData !== undefined">
                        <h3 style="color: #222;">Images Product</h3>
                        <div style="background-color: #f3f3f3;color: #000;border-radius: 0px;border: 1px solid #ddd;">
                            <div class="p-formgroup-inline" style="border-bottom: 1px solid #ddd;">
                                <div class="p-col-12 p-sm-12 p-md-6 p-lg-6"><b>Note back 1:</b> {{productData.imagesProduct.noteBack1}}</div>
                                <div class="p-col-12 p-sm-12 p-md-6 p-lg-6"><b>Note back 2:</b> {{productData.imagesProduct.noteBack2}}</div>
                            </div>
                            <div class="p-formgroup-inline">
                                <div class="p-col-12 p-sm-12 p-md-6 p-lg-6"><b>Note embrodeiry:</b> {{productData.imagesProduct.noteEmbrodeiry}}</div>
                                <div class="p-col-12 p-sm-12 p-md-6 p-lg-6"><b>Note extra:</b> {{productData.imagesProduct.noteExtra}}</div>
                            </div>
                            
                        </div>
                        <div style="background-color: #fff;color: #000;border-radius: 0px;border: 1px solid #ddd;margin-top: 10px;">
                            <div class="p-formgroup-inline" style="border-top:1px solid #ddd;">
                                <div class="p-col-12 p-sm-12 p-md-6 p-lg-3" style="text-align: center;"><b>Image back 1</b>
                                    <br/>
                                    <img *ngIf="productData.imagesProduct.imageBack1 != '' && productData.imagesProduct.imageBack1 != null" [src]="sanitize(imagesUrl+productData.imagesProduct.imageBack1.blobFilePath)" style="width:300px;margin:0 auto;">
                                </div>
                                <div class="p-col-12 p-sm-12 p-md-6 p-lg-3" style="text-align: center;"><b>Image back 2</b>
                                    <br/>
                                    <img *ngIf="productData.imagesProduct.imageBack2 != '' && productData.imagesProduct.imageBack2 != null" [src]="sanitize(imagesUrl+productData.imagesProduct.imageBack2.blobFilePath)" style="width:300px;margin:0 auto;">
                                </div>
                                <div class="p-col-12 p-sm-12 p-md-6 p-lg-3" style="text-align: center;"><b>Image embrodeiry</b>
                                    <br/>
                                    <img *ngIf="productData.imagesProduct.imageEmbrodeiry != '' && productData.imagesProduct.imageEmbrodeiry != null" [src]="sanitize(imagesUrl+productData.imagesProduct.imageEmbrodeiry.blobFilePath)" style="width:300px;margin:0 auto;">
                                </div>
                                <div class="p-col-12 p-sm-12 p-md-6 p-lg-3" style="text-align: center;"><b>Image extra</b>
                                    <br/>
                                    <img *ngIf="productData.imagesProduct.imageExtra1 != '' && productData.imagesProduct.imageExtra1 != null" [src]="sanitize(imagesUrl+productData.imagesProduct.imageExtra.blobFilePath)" style="width:300px;margin:0 auto;">
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="card" *ngIf="productData !== undefined">
                        <h3 style="color: #222;">Fitting</h3>
                        <div style="background-color: #fff;color: #000;border-radius: 0px;margin-top: 10px;">
                            <div class="p-formgroup-inline">
                                <div class="p-col-12 p-sm-12 p-md-12 p-lg-12" *ngFor="let lsItem of productData.lstFitting">
                                    <div style="background-color: #f3f3f3;color: #000;border-radius: 0px;border: 1px solid #ddd;">
                                        <div class="p-formgroup-inline">
                                            <div class="p-col-12 p-sm-12 p-md-2 p-lg-2">
                                                <b>Present</b><br/>
                                                {{lsItem.whopress}}
                                            </div>
                                            <div class="p-col-12 p-sm-12 p-md-2 p-lg-2">
                                                <b>Style note prototype</b><br/>
                                                {{lsItem.styleNoteprototype}}
                                            </div>
                                            <div class="p-col-12 p-sm-12 p-md-2 p-lg-2 center">
                                                <img [src]="sanitize(imagesUrl+lsItem.imagePrototype1.blobFilePath)" *ngIf="lsItem.imagePrototype1 != null " style="width:100%;margin:0 auto;">
                                                <br/>Image prototype 1
                                            </div>
                                            <div class="p-col-12 p-sm-12 p-md-2 p-lg-2 center">
                                                <img [src]="sanitize(imagesUrl+lsItem.imagePrototype2.blobFilePath)" *ngIf="lsItem.imagePrototype2 != null" style="width:100%;margin:0 auto;">
                                                <br/>Image prototype 2
                                            </div>
                                            <div class="p-col-12 p-sm-12 p-md-2 p-lg-2 center">
                                                <img [src]="sanitize(imagesUrl+lsItem.imagePrototype3.blobFilePath)" *ngIf="lsItem.imagePrototype3 != null" style="width:100%;margin:0 auto;">
                                                <br/>Image prototype 3
                                            </div>
                                            <div class="p-col-12 p-sm-12 p-md-2 p-lg-2 center">
                                                <img [src]="sanitize(imagesUrl+lsItem.imagePrototype4.blobFilePath)" *ngIf="lsItem.imagePrototype4 != null" style="width:100%;margin:0 auto;">
                                                <br/>Image prototype 4
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                </div>
            </div>
            
        </div>
        <ng-template pTemplate="footer">
            <div style="text-align:right;margin-top:15px;" *ngIf="collectionPlanBackBtnVisible || isCollectionDetail"> <button class="" style="background-color: #000;
                color: #fff;
                font-size: 14px;
                font-weight: normal;
                border: 0px;
                border-radius: 0px;
                padding: 35px;
    padding-top: 5px;
    padding-bottom: 5px;" (click)="(productsListVisible || showProductDetailView || collectionPlanDetailVisible ? goBackTo() : (isCollectionDetail ? goBackToSamplesCategories()  : goBackTo()))">Back</button></div>
           
            <button pButton pRipple type="button"
                (click)="closeCollectionPlansListModal()" class="p-button-default close-btn-des">
                <img src="../../../assets/images/close-icon.png">
            </button>
        </ng-template>
    </p-dialog>

    <p-dialog header="&nbsp;" [(visible)]="projectsListModal" [modal]="true" [style]="{width: '1000px'}"
        styleClass="p-fluid" [baseZIndex]="10000" [draggable]="false" [resizable]="false">
        <div class="internal-modal-header">
            <h2 style="text-transform: capitalize;"><b>{{activeModalTitle.title}}</b> {{activeModalTitle.subtitle}}</h2>
            <small>Complete list</small>
            <div class="form-search-box"><i class="pi pi-search"></i><input type="text"
                    (input)="filterProjectsList($event)" placeholder="Search..." /></div>
        </div>
        <div class="internal-modal-form">
            <ul *ngIf="projectList.length > 0 && !projectListLoading">
                <li *ngFor="let element of projectList">
                    <h4>{{element.name}}</h4>
                    <p>{{element.description}}</p>
                    <button style="display: none;"
                        (click)="getSelectedProjectDetail(element.id)">View project</button>
                </li>
            </ul>
            <div class="no-results-list" *ngIf="projectList.length == 0 && !projectListLoading">
                There is no data available here
            </div>
            <div class="loading-results-list" *ngIf="projectListLoading">
                Loading...
            </div>
        </div>
        <ng-template pTemplate="footer">
            <button pButton pRipple icon="pi pi-times" type="button"
                (click)="closeProjectsListModal()" class="p-button-default close-btn-des"></button>
        </ng-template>
    </p-dialog>
</div>