import { Component, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormControl,
  FormGroup,
  NgForm,
  Validators,
} from "@angular/forms";
import { AppComponent } from '../../../app.component';
import { LoginUser } from '../domain/login';
import { User } from '../domain/user';
import { UserService } from '../service/userservice';
import { catchError, finalize, map, tap } from "rxjs/operators";
import { of } from 'rxjs';
import { Message } from 'primeng/api';
import { MessageService } from 'primeng/api';
import { Router } from '@angular/router';
import { AppGlobals } from 'src/app/app.globals';

@Component({
  selector: 'app-login',
  templateUrl: './app.login.component.html',
  styleUrls: ['./app.login.component.scss']
})

export class AppLoginComponent implements OnInit {

    public user: LoginUser;
    public msgs: Message[] = [];
    public finished: boolean = true;

    constructor(public app: AppComponent, 
                private router: Router,
                private userService: UserService,
                private messageService: MessageService) {}

    ngOnInit(): void {
      this.user = {};
    }

    onLogin(form: NgForm)
    {
      this.finished = false;
      this.userService.loginAuthentication(this.user.username, this.user.password)
      .pipe
      (tap((res) => {
          this.finished = true;
          
          let displayUsername = res.data.name + " " + res.data.surname;
          
          if(res.data.companyName != undefined && res.data.companyName != null && res.data.companyName != "")
          {
            displayUsername += ", "+res.data.companyName;
          }

          AppGlobals.storage().set("username", displayUsername);

          console.log(res.data);
          sessionStorage.setItem("alkenium_Permissions", btoa(res.data.permissionCodes));
          sessionStorage.setItem("alkenium_Token", res.data.token);
          sessionStorage.setItem("serviceInstanceId", res.data.serviceInstanceId);
          this.router.navigate(["/home"]);
      }),
      catchError((res) => {
        this.finished = true;
        this.messageService.add({
          severity: "error",
          summary: "Error",
          life: 100000,
          detail: res.status == 401 
            ? 'Login failed. Email or Password wrong' 
            : typeof res.message !== "undefined" && res.message !== null 
            ? res.message 
            : res.error?.title,
        });
        return of();
      }))
      .subscribe((data) => {
      });
    }
}